import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { birthdaypackages } from "../data/birthdaydata"; // Ensure this data is added in your file
import ViewMoreCard from "./viewcard";
import { Link } from "react-router-dom";
import backgroundImage from "../images/DALL·E-2024-11-09-10.16.jpeg";
import funimage from "../images/DALL·E-2024-11-09-17.34.jpg";
import prewedding from "../images/pexels-asadphoto-169196.jpg";
import wedding from "../images/Weddingphoto.jpg";
import reception from "../images/wb7.jpg";
import babynaming from "../images/babynaming.jpg";
import babyshower from "../images/bs10.jpg";
import babylast from "../images/babylast.jpg";
import weddingimage from "../images/weddingimage.jpg";
import babyshower1 from "../images/babyshower1.jpg";
import babyshower2 from "../images/babyshower2.jpg";
import babyshower3 from "../images/babyshower3.jpg";
import FAQ from "./Faq";

// Dummy data for gallery images
export const galleryImages = [
  {
    src: "path-to-gallery-image-1.jpg",
    alt: "Gallery Image 1",
  },
  {
    src: "path-to-gallery-image-2.jpg",
    alt: "Gallery Image 2",
  },
  // Add more gallery images as needed
];

// Dummy data for testimonials
export const testimonials = [
  {
    message: "The decorations were fantastic and made the event unforgettable!",
    name: "John Doe",
  },
  {
    message: "Amazing service and beautiful designs, highly recommend!",
    name: "Jane Smith",
  },
  // Add more testimonials as needed
];

// Dummy data for team members
export const teamMembers = [
  {
    image: "path-to-member-image-1.jpg",
    name: "Alice Johnson",
    role: "Event Coordinator",
  },
  {
    image: "path-to-member-image-2.jpg",
    name: "Bob Smith",
    role: "Lead Designer",
  },
  // Add more team members as needed
];

const SearchSection = ({ searchQuery, setSearchQuery, handleSearchSubmit }) => {
  return (
    <label className="flex flex-col min-w-40 h-14 w-full max-w-[480px] @[480px]:h-16">
      <form onSubmit={handleSearchSubmit}>
        <div className="flex w-full flex-1 items-stretch rounded-xl h-full">
          <div className="text-neutral-500 flex border-none bg-[#EEEEEE] items-center justify-center pl-4 rounded-l-xl border-r-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
            </svg>
          </div>
          <input
            placeholder="Search for a project"
            className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-black focus:outline-0 focus:ring-0 border-none bg-[#EEEEEE] focus:border-none h-full placeholder:text-neutral-500 px-4 rounded-r-none border-r-0 pr-2 rounded-l-none border-l-0 pl-2 text-sm font-normal leading-normal"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="flex items-center justify-center rounded-r-xl border-l-0 border-none bg-[#EEEEEE] pr-2">
            <button
              className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-2 sm:px-4 bg-[#EA2831] text-[#FFFFFF] text-sm font-bold leading-normal tracking-[0.015em]"
              type="submit"
            >
              <span className="truncate">Go</span>
            </button>
          </div>
        </div>
      </form>
    </label>
  );
};

const NewPage1 = () => {
  const [visiblePackages, setVisiblePackages] = useState(6);

  const loadMorePackages = () => {
    setVisiblePackages((prev) => prev + 6);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Helmet>
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com/"
          crossOrigin=""
        />
        <link
          rel="stylesheet"
          as="style"
          onLoad="this.rel='stylesheet'"
          href="https://fonts.googleapis.com/css2?display=swap&amp;family=Manrope%3Awght%40400%3B500%3B700%3B800&amp;family=Noto+Sans%3Awght%40400%3B500%3B700%3B900"
        />
        <title>Galileo Design</title>
        <link rel="icon" type="image/x-icon" href="data:image/x-icon;base64," />
        <script src="https://cdn.tailwindcss.com?plugins=forms,container-queries"></script>
      </Helmet>
      <div
        className="relative flex size-full min-h-screen flex-col bg-slate-50 group/design-root overflow-x-hidden"
        style={{ fontFamily: 'Manrope, "Noto Sans", sans-serif' }}
      >
        <div className="layout-container px-4 sm:px-8 md:px-12 flex h-full grow flex-col">
          <div className="w-full relative bg-gradient-to-r text-black px-2 py-8 sm:px-3 sm:py-16">
            <div className="@container">
              <div className="flex flex-col gap-6 px-2 py-6 sm:px-4 sm:py-10 @[480px]:gap-8 @[864px]:flex-row">
                <div className="flex flex-col gap-6 @[480px]:min-w-[400px] @[480px]:gap-8 @[864px]:justify-center">
                  <div className="flex flex-col gap-2 text-left">
                    <h1 className="text-[#0e141b] text-4xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em]">
                      Celebrate in Style with Our Exclusive Packages
                    </h1>
                    <h2 className="text-[#0e141b] text-sm font-normal leading-normal @[480px]:text-base @[480px]:font-normal @[480px]:leading-normal">
                      Discover the perfect blend of stunning decorations and
                      memorable themes tailored to create unforgettable
                      celebrations. Explore our detailed packages and make every
                      moment extraordinary.{" "}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="layout-content-container flex flex-col  flex-1">
            <div className="@container px-4 sm:px-8 md:px-12">
              <div className="flex flex-col gap-6 px-2 py-6 sm:px-4 sm:py-10 @[480px]:gap-8 @[864px]:flex-row">
                <div
                  className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl @[480px]:h-auto @[480px]:min-w-[400px] @[864px]:w-full"
                  style={{ backgroundImage: `url(${backgroundImage})` }}
                ></div>
                <div className="flex flex-col gap-6 @[480px]:min-w-[400px] @[480px]:gap-8 @[864px]:justify-center">
                  <div className="flex flex-col gap-2 text-left">
                    <h1 className="text-[#0e141b] text-4xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em]">
                      Introducing: Birthday Celebration Package
                    </h1>
                    <h2 className="text-[#0e141b] text-sm font-normal leading-normal @[480px]:text-base @[480px]:font-normal @[480px]:leading-normal">
                      Here’s an alternative description about birthday package
                      details: Explore our birthday packages in detail. Discover
                      the perfect combination of decorations and themes designed
                      to create lasting memories.
                    </h2>
                  </div>
                  <div className="flex-wrap gap-3 flex">
                    <Link onClick={scrollToTop} to="/birthday">
                      <button className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 @[480px]:h-12 @[480px]:px-5 bg-[#1980e6] text-slate-50 text-sm font-bold leading-normal tracking-[0.015em] @[480px]:text-base @[480px]:font-bold @[480px]:leading-normal @[480px]:tracking-[0.015em]">
                        <span className="truncate">Discover More</span>
                      </button>
                    </Link>
                    {/* <button className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 @[480px]:h-12 @[480px]:px-5 bg-[#e7edf3] text-[#0e141b] text-sm font-bold leading-normal tracking-[0.015em] @[480px]:text-base @[480px]:font-bold @[480px]:leading-normal @[480px]:tracking-[0.015em]">
                                                <span className="truncate">Access Feature</span>
                                            </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="layout-container flex flex-col w-full gap-12 px-4 py-8 sm:px-5 sm:py-10 md:px-10 md:py-20">
              {/* Baby Category Section */}
              <div className="w-full bg-blue-100 rounded-lg shadow-lg relative p-4 sm:p-6 md:p-8">
                {/* Title and Description Below the Images */}
                <div className="max-w-3xl mx-auto text-center">
                  <h1 className="text-xl sm:text-2xl md:text-3xl font-black text-blue-900 mb-3 sm:mb-4 md:mb-6">
                    Celebrate Your Baby's Precious Moments 👶
                  </h1>
                  <p className="text-sm sm:text-base md:text-lg text-gray-700 max-w-xl mx-auto mb-4 sm:mb-6 md:mb-8">
                    Cherish every special occasion for your little one. From
                    baby showers to naming ceremonies, we have the perfect
                    package for every event.
                  </p>
                </div>

                {/* Cards Section */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 md:gap-8 justify-center mb-4 sm:mb-6 md:mb-10">
                  {/* Baby Shower Package */}
                  <div
                    className="w-full max-w-xs mx-auto rounded-full bg-cover bg-center border-2 sm:border-4 md:border-6 border-white shadow-md hover:shadow-lg transition-transform transform hover:scale-105"
                    style={{
                      backgroundImage: `url(${babyshower})`,
                      aspectRatio: "1/1",
                    }}
                  ></div>
                  {/* Naming Ceremony Package */}
                  <div
                    className="w-full max-w-xs mx-auto rounded-full bg-cover bg-center border-2 sm:border-4 md:border-6 border-white shadow-md hover:shadow-lg transition-transform transform hover:scale-105"
                    style={{
                      backgroundImage: `url(${babynaming})`,
                      aspectRatio: "1/1",
                    }}
                  ></div>
                  {/* First Birthday Package */}
                  <div
                    className="w-full max-w-xs mx-auto rounded-full bg-cover bg-center border-2 sm:border-4 md:border-6 border-white shadow-md hover:shadow-lg transition-transform transform hover:scale-105"
                    style={{
                      backgroundImage: `url(${babylast})`,
                      aspectRatio: "1/1",
                    }}
                  ></div>
                </div>

                {/* Discover More Button */}
                <div className="text-center">
                  <Link onClick={scrollToTop} to="/babyshower">
                    <button className="px-3 py-2 sm:px-5 sm:py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition">
                      Discover More
                    </button>
                  </Link>
                </div>
              </div>

              <div className="container mx-auto py-8 px-4 sm:py-8 sm:px-4 md:py-16 md:px-8 relative">
                <div className="relative bg-[#f8f9fa] rounded-2xl shadow-md overflow-hidden">
                  <div className="absolute -top-8 -left-8 w-40 h-40 bg-gradient-to-br from-[#ff7e5f] to-[#feb47b] rounded-full opacity-40 transform scale-150"></div>
                  <div className="absolute -bottom-8 -right-8 w-40 h-40 bg-gradient-to-br from-[#ff7e5f] to-[#feb47b] rounded-full opacity-40 transform scale-150"></div>
                  <div className="flex flex-col md:flex-row items-center md:items-start gap-4 md:gap-8 p-4 md:p-16 relative z-10">
                    <div className="w-full md:w-1/2">
                      <div
                        className="w-full h-72 md:h-96 bg-cover bg-center rounded-xl shadow-lg transform transition-transform duration-300 hover:scale-105"
                        style={{ backgroundImage: `url(${funimage})` }}
                      ></div>
                    </div>
                    <div className="w-full md:w-1/2 flex flex-col gap-6">
                      <h1 className="text-4xl md:text-5xl font-bold text-[#2d3748]">
                        Let's Make Unforgettable Memories!
                      </h1>
                      <p className="text-lg text-gray-700 leading-relaxed">
                        Dive into an array of fun-filled activities crafted to
                        bring joy and laughter. From games to adventures, every
                        moment is designed to make you smile!
                      </p>
                      <div className="flex flex-col sm:flex-row gap-4 mt-4">
                        <Link onClick={scrollToTop} to="/funactivites">
                          <button className="px-4 py-2 sm:px-5 sm:py-3 bg-[#ff6f61] text-white rounded-full text-xs sm:text-sm font-semibold hover:bg-[#e55b52] transition-all duration-300">
                            <span>Discover More</span>
                          </button>
                        </Link>
                        <Link
                          onClick={scrollToTop}
                          to="https://wa.me/+917338011713"
                        >
                          <button className="px-4 py-2 sm:px-5 sm:py-3 border border-[#ff6f61] text-[#ff6f61] rounded-full text-xs sm:text-sm font-semibold hover:bg-[#ffefef] transition-all duration-300">
                            <span>Get Started</span>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Wedding Category Section */}
              <div className="w-full bg-white rounded-lg shadow-lg p-12">
                <div className="max-w-6xl mx-auto text-center mb-12">
                  <h1 className="text-4xl font-bold text-[#c2185b] mb-6">
                    Wedding Celebration Packages 💍
                  </h1>
                  <p className="text-lg text-gray-700 max-w-4xl mx-auto mb-10">
                    From pre-wedding events to the big day and reception, we
                    have everything to make your wedding truly magical.
                  </p>
                </div>

                <div className="flex flex-wrap gap-10 justify-center mb-10">
                  {/* Pre-Wedding */}
                  <div className="w-[350px] h-[400px] bg-[#fce4ec] rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105">
                    <div
                      className="w-full h-52 bg-cover bg-center rounded-lg mb-4"
                      style={{ backgroundImage: `url(${prewedding})` }}
                    ></div>
                    <h2 className="text-2xl font-bold text-[#c2185b] mb-2">
                      Pre-Wedding Events
                    </h2>
                    <p className="text-sm text-gray-600">
                      Engagements, haldi, and mehendi ceremonies with elegant
                      decor for unforgettable moments.
                    </p>
                  </div>
                  {/* Wedding */}
                  <div className="w-[350px] h-[400px] bg-[#fce4ec] rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105">
                    <div
                      className="w-full h-52 bg-cover bg-center rounded-lg mb-4"
                      style={{ backgroundImage: `url(${wedding})` }}
                    ></div>
                    <h2 className="text-2xl font-bold text-[#c2185b] mb-2">
                      Wedding Day
                    </h2>
                    <p className="text-sm text-gray-600">
                      Elegant and stunning decorations for the most important
                      day of your life.
                    </p>
                  </div>
                  {/* Reception */}
                  <div className="w-[350px] h-[400px] bg-[#fce4ec] rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105">
                    <div
                      className="w-full h-52 bg-cover bg-center rounded-lg mb-4"
                      style={{ backgroundImage: `url(${reception})` }}
                    ></div>
                    <h2 className="text-2xl font-bold text-[#c2185b] mb-2">
                      Reception
                    </h2>
                    <p className="text-sm text-gray-600">
                      Celebrate your union with family and friends – beautiful
                      decorations and stunning themes.
                    </p>
                  </div>
                </div>
                <div className="text-center">
                  <Link onClick={scrollToTop} to="/wedding">
                    <button className="px-8 py-3 bg-[#c2185b] text-white rounded-lg hover:bg-[#ad1457]">
                      Discover More
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            {/* Birthday Packages Section */}
            <div className="py-8 sm:py-12 md:py-16 bg-[#f9fafb] text-center px-4 sm:px-8 md:px-12">
              <h2 className="text-2xl sm:text-3xl font-bold text-[#0e141b] mb-4 sm:mb-6">
                Our Birthday Packages
              </h2>
              <p className="text-base sm:text-lg text-[#4e7397] mb-6 sm:mb-8 md:mb-10 mx-auto max-w-xl sm:max-w-2xl">
                Whether you're planning an intimate gathering or a grand
                celebration, our birthday packages are designed to bring your
                vision to life. From personalized themes to elegant decorations,
                we’ve got something for every occasion!
              </p>

              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 sm:gap-8 md:gap-10">
                {/* Classic Birthday Package */}
                <div className="bg-white p-4 sm:p-6 md:p-8 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
                  <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-[#0e141b] mb-2 sm:mb-3 md:mb-4">
                    Classic Birthday Package
                  </h3>
                  <p className="text-sm sm:text-base text-[#4e7397] mb-3 sm:mb-4 md:mb-6">
                    A timeless, elegant decoration set for a perfect birthday
                    celebration. Enjoy customized color schemes, balloon arches,
                    and personalized table settings.
                  </p>
                  <ul className="text-left text-xs sm:text-sm md:text-base text-[#4e7397] mb-3 sm:mb-4 md:mb-6">
                    <li>Balloon Arch</li>
                    <li>Customized Balloon Bouquet</li>
                    <li>Table and Chair Decorations</li>
                  </ul>
                  <Link to="https://wa.me/+917338011713">
                    <button className="w-full bg-red-500 text-white py-2 sm:py-3 rounded-full font-semibold shadow-md transition-all duration-300 transform hover:bg-red-600">
                      Book Now
                    </button>
                  </Link>
                </div>

                {/* Premium Birthday Package */}
                <div className="bg-white p-4 sm:p-6 md:p-8 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
                  <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-[#0e141b] mb-2 sm:mb-3 md:mb-4">
                    Premium Birthday Package
                  </h3>
                  <p className="text-sm sm:text-base text-[#4e7397] mb-3 sm:mb-4 md:mb-6">
                    Elevate your celebration with a premium package that
                    includes a unique balloon backdrop, custom party favors, and
                    exquisite table centerpieces.
                  </p>
                  <ul className="text-left text-xs sm:text-sm md:text-base text-[#4e7397] mb-3 sm:mb-4 md:mb-6">
                    <li>Themed Balloon Backdrop</li>
                    <li>Custom Party Favors</li>
                    <li>Decorative Table Centerpieces</li>
                  </ul>
                  <Link to="https://wa.me/+917338011713">
                    <button className="w-full bg-red-500 text-white py-2 sm:py-3 rounded-full font-semibold shadow-md transition-all duration-300 transform hover:bg-red-600">
                      Book Now
                    </button>
                  </Link>
                </div>

                {/* Deluxe Birthday Package */}
                <div className="bg-white p-4 sm:p-6 md:p-8 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
                  <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-[#0e141b] mb-2 sm:mb-3 md:mb-4">
                    Deluxe Birthday Package
                  </h3>
                  <p className="text-sm sm:text-base text-[#4e7397] mb-3 sm:mb-4 md:mb-6">
                    Our Deluxe package offers a luxurious experience with a
                    grand balloon arch, full venue decor, and a customized photo
                    booth area for unforgettable memories.
                  </p>
                  <ul className="text-left text-xs sm:text-sm md:text-base text-[#4e7397] mb-3 sm:mb-4 md:mb-6">
                    <li>Grand Balloon Arch</li>
                    <li>Full Venue Decoration</li>
                    <li>Personalized Photo Booth</li>
                  </ul>
                  <Link to="https://wa.me/+917338011713">
                    <button className="w-full bg-red-500 text-white py-2 sm:py-3 rounded-full font-semibold shadow-md transition-all duration-300 transform hover:bg-red-600">
                      Book Now
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            {/* Our Process */}
            <div className="py-16 bg-slate-50 text-center px-4 sm:px-8 md:px-12">
              <h2 className="text-3xl font-bold text-[#0e141b] mb-8">
                Our Process & Special Offers
              </h2>
              <div className="mb-12">
                <h3 className="text-2xl font-semibold text-[#0e141b] mb-6">
                  Our Process
                </h3>
                <div className="space-y-4 max-w-4xl mx-auto text-left">
                  <div className="flex items-start">
                    <span className="text-blue-500 text-2xl font-bold mr-4">
                      1.
                    </span>
                    <div>
                      <h4 className="text-xl font-semibold mb-1">
                        Consultation
                      </h4>
                      <p className="text-sm text-[#4e7397]">
                        We listen to your ideas and discuss your vision.
                      </p>
                      <p className="hidden lg:block text-sm text-[#6b7280] mt-2">
                        We dedicate time to understanding your needs and
                        preferences, ensuring we grasp every detail to make your
                        event exceptional.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <span className="text-blue-500 text-2xl font-bold mr-4">
                      2.
                    </span>
                    <div>
                      <h4 className="text-xl font-semibold mb-1">Planning</h4>
                      <p className="text-sm text-[#4e7397]">
                        We create a customized plan to bring your vision to
                        life.
                      </p>
                      <p className="hidden lg:block text-sm text-[#6b7280] mt-2">
                        Our team develops a step-by-step strategy, outlining
                        every aspect from setup to execution, so everything runs
                        smoothly.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <span className="text-blue-500 text-2xl font-bold mr-4">
                      3.
                    </span>
                    <div>
                      <h4 className="text-xl font-semibold mb-1">Execution</h4>
                      <p className="text-sm text-[#4e7397]">
                        Our team sets up everything to perfection for your
                        event.
                      </p>
                      <p className="hidden lg:block text-sm text-[#6b7280] mt-2">
                        We manage every detail on the day of your event,
                        ensuring everything is perfectly executed so you can
                        relax and enjoy the moment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Special Offers */}
              <div>
                <h3 className="text-2xl font-semibold text-[#0e141b] mb-6">
                  Special Offers
                </h3>
                <div className="space-y-4 max-w-4xl mx-auto text-left">
                  <div className="flex items-start">
                    <span className="text-green-500 text-2xl font-bold mr-4">
                      •
                    </span>
                    <div>
                      <h4 className="text-xl font-semibold mb-1">
                        10% Off First Booking
                      </h4>
                      <p className="text-sm text-[#4e7397]">
                        Book your first event with us and get 10% off!
                      </p>
                      <p className="hidden lg:block text-sm text-[#6b7280] mt-2">
                        This limited-time offer is our way of welcoming you and
                        giving you the opportunity to experience our premium
                        services at a discount.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <span className="text-green-500 text-2xl font-bold mr-4">
                      •
                    </span>
                    <div>
                      <h4 className="text-xl font-semibold mb-1">
                        Bundle Discounts
                      </h4>
                      <p className="text-sm text-[#4e7397]">
                        Save more when you book multiple services together.
                      </p>
                      <p className="hidden lg:block text-sm text-[#6b7280] mt-2">
                        Enjoy greater savings when you opt for our bundled
                        services, perfect for comprehensive event planning and
                        decor solutions.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <span className="text-green-500 text-2xl font-bold mr-4">
                      •
                    </span>
                    <div>
                      <h4 className="text-xl font-semibold mb-1">
                        Seasonal Deals
                      </h4>
                      <p className="text-sm text-[#4e7397]">
                        Check out our limited-time offers for seasonal events.
                      </p>
                      <p className="hidden lg:block text-sm text-[#6b7280] mt-2">
                        Take advantage of our exclusive seasonal discounts to
                        make your holiday parties and themed events more
                        memorable and cost-effective.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="@container px-4 sm:px-8 md:px-12">
              <div className="relative flex flex-col gap-10 px-8 py-16 bg-[#fef3c7] rounded-none @[480px]:gap-12 @[864px]:flex-row overflow-hidden">
                <div className="absolute top-0 left-0 w-32 h-32 bg-[#facc15] rounded-full blur-3xl opacity-30 transform translate-x-[-50%] translate-y-[-50%]"></div>
                <div className="absolute bottom-0 right-0 w-40 h-40 bg-[#fbbf24] rounded-full blur-3xl opacity-30 transform translate-x-[50%] translate-y-[50%]"></div>
                <div
                  className="w-full bg-center bg-no-repeat aspect-[16/9] bg-cover rounded-xl shadow-lg @[480px]:h-auto @[480px]:min-w-[350px] @[864px]:w-full"
                  style={{ backgroundImage: `url(${funimage})` }}
                ></div>
                <div className="flex flex-col gap-4 @[480px]:min-w-[350px] @[480px]:gap-6 @[864px]:justify-center text-[#1f2937] z-10">
                  <h1 className="text-4xl font-extrabold leading-snug tracking-wide @[480px]:text-5xl @[480px]:leading-snug">
                    Let the Adventure Begin!
                  </h1>
                  <p className="text-lg font-medium leading-relaxed @[480px]:text-xl @[480px]:leading-relaxed">
                    Discover activities that burst with fun and energy. From
                    creative workshops to thrilling outdoor adventures, there’s
                    excitement for everyone!
                  </p>
                  <div className="flex flex-col sm:flex-row gap-4 mt-4">
                    <Link onClick={scrollToTop} to="/funactivites">
                      <button className="w-full sm:w-auto flex items-center justify-center rounded-lg h-12 px-4 sm:px-6 bg-[#fb923c] text-white text-sm sm:text-base font-bold tracking-wide shadow-md hover:bg-[#f97316] transition-all duration-300">
                        <span className="truncate">Join the Fun</span>
                      </button>
                    </Link>
                    <button className="w-full sm:w-auto flex items-center justify-center rounded-lg h-12 px-4 sm:px-6 bg-[#fde68a] text-[#1f2937] text-sm sm:text-base font-bold tracking-wide shadow-md hover:bg-[#fcd34d] transition-all duration-300">
                      <span className="truncate">Get Involved</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <h3 className="text-2xl font-semibold text-[#0e141b] mb-6">
              Gallery
            </h3>
            <div className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-5 p-4">
              {birthdaypackages.slice(0, visiblePackages).map((item, index) => (
                <div key={index} className="flex flex-col gap-4 pb-4">
                  <div
                    className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                    style={{ backgroundImage: `url("${item.image}")` }}
                  ></div>
                  <div>
                    <p className="text-[#0e141b] text-base font-medium leading-normal">
                      {item.title}
                    </p>
                    <p className="text-[#4e7397] text-sm font-normal leading-normal">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
              <div className="flex flex-col gap-4 pb-4">
                <div className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl">
                  <Link onClick={scrollToTop} to="/birthday">
                    <ViewMoreCard />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full relative bg-gradient-to-r from-gray-700 to-red-600 text-white px-5 py-16">
            <div className="flex flex-col md:flex-row items-center md:items-start justify-between mx-40">
              <div className="max-w-lg md:text-left text-center mb-8 md:mb-0">
                <h2 className="text-3xl md:text-4xl font-bold leading-tight text-white">
                  Ready to Plan Your Celebration?
                </h2>
                <p className="text-lg md:text-xl mt-4 text-gray-200">
                  Contact us today to discuss your ideas and let us make your
                  event unforgettable!
                </p>
              </div>
              <div className="flex justify-center md:justify-start">
                <Link to="https://wa.me/+917338011713">
                  <button className="bg-red-500 text-white py-3 px-10 rounded-full font-semibold shadow-md transition-all duration-300 transform hover:scale-105 hover:bg-red-600">
                    Book Now
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap w-full bg-white py-20 px-10">
            <div className="flex w-full md:w-1/2 gap-4">
              {/* Image Gallery on the Left */}
              <div
                className="w-full aspect-square rounded-lg shadow-lg bg-cover bg-center hidden md:block"
                style={{ backgroundImage: `url(${weddingimage})` }}
              ></div>
            </div>
            <div className="flex flex-col w-full md:w-1/2 justify-center px-10">
              <h1 className="text-5xl font-bold text-[#c2185b] mb-6">
                Wedding Celebration Package 💍
              </h1>
              <p className="text-gray-700 text-lg leading-8 mb-8">
                Let us make your wedding day perfect with elegant decorations
                and unforgettable moments. Our curated package offers a magical
                setting that will make your special day truly one of a kind.
              </p>
              <Link onClick={scrollToTop} to="/wedding">
                <button className="bg-[#c2185b] text-white py-4 px-8 rounded-md font-medium text-lg hover:bg-[#b0003a] transition-all duration-300 shadow-md">
                  Discover More
                </button>{" "}
              </Link>
            </div>
          </div>
          <div className="relative py-20 bg-blue-100 px-6 overflow-hidden">
            <div className="absolute -top-20 right-10 w-32 h-32 rounded-full bg-blue-300 opacity-50"></div>
            <div className="absolute bottom-10 -left-20 w-40 h-40 rounded-full bg-blue-200 opacity-60"></div>

            <div className="max-w-5xl mx-auto text-center mb-12">
              <h1 className="text-4xl font-black text-blue-900 mb-4">
                Welcome the Little One: Baby Shower Package 👶
              </h1>
              <p className="text-lg text-gray-700 max-w-3xl mx-auto mb-10">
                Celebrate the arrival of your little bundle of joy with a
                delightful baby shower package. We have everything you need for
                a heartwarming celebration.
              </p>
            </div>

            <div className="flex flex-wrap gap-8 justify-center">
              <div
                className="w-[250px] h-[250px] rounded-full bg-cover bg-center border-8 border-white shadow-md hover:shadow-lg transition duration-500 transform hover:scale-105"
                style={{ backgroundImage: `url(${babyshower3})` }}
              ></div>
              <div
                className="w-[250px] h-[250px] rounded-full bg-cover bg-center border-8 border-white shadow-md hover:shadow-lg transition duration-500 transform hover:scale-105"
                style={{ backgroundImage: `url(${babyshower2})` }}
              ></div>
              <div
                className="w-[250px] h-[250px] rounded-full bg-cover bg-center border-8 border-white shadow-md hover:shadow-lg transition duration-500 transform hover:scale-105"
                style={{ backgroundImage: `url(${babyshower1})` }}
              ></div>
            </div>

            <div className="flex justify-center mt-10">
              <Link onClick={scrollToTop} to="/babyshower">
                <button className="bg-blue-600 text-white py-4 px-8 rounded-full text-lg font-bold shadow-md hover:bg-blue-700 transition-transform transform hover:scale-105 duration-300">
                  Discover More
                </button>
              </Link>
            </div>
          </div>

          <div className="flex flex-col gap-12 px-6 py-12 bg-[#F9FAFB] rounded-lg">
            {/* Why Choose Us Section */}
            <div className="text-center mb-8">
              <h1 className="text-3xl font-extrabold text-[#D72638] mb-4">
                Why Choose Us
              </h1>
              <p className="text-gray-600 max-w-3xl mx-auto text-lg">
                We deliver extraordinary celebrations with exceptional service
                and attention to detail.
              </p>
            </div>

            {/* Features Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  title: "Unique Themes",
                  description:
                    "Choose from a variety of creative themes that make every celebration memorable.",
                  icon: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36px"
                      height="36px"
                      fill="currentColor"
                      viewBox="0 0 256 256"
                    >
                      <path d="M128 16a104 104 0 10104 104A104.12 104.12 0 00128 16zm0 192a88 88 0 1188-88 88.1 88.1 0 01-88 88zm0-152a64 64 0 1064 64 64.07 64.07 0 00-64-64z" />
                    </svg>
                  ),
                },
                {
                  title: "Personalized Decorations",
                  description:
                    "Tailor your decorations to your vision, from colors to custom messages.",
                  icon: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36px"
                      height="36px"
                      fill="currentColor"
                      viewBox="0 0 256 256"
                    >
                      <path d="M224 104h-96V40a8 8 0 00-16 0v64H32a8 8 0 000 16h80v56a8 8 0 0016 0v-56h96a8 8 0 000-16z" />
                    </svg>
                  ),
                },
                {
                  title: "Special Offers",
                  description:
                    "Enjoy exclusive deals and discounts on our celebration packages.",
                  icon: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36px"
                      height="36px"
                      fill="currentColor"
                      viewBox="0 0 256 256"
                    >
                      <path d="M128 24a16 16 0 1016 16 16 16 0 00-16-16zm72 40h-40a8 8 0 010-16h40a8 8 0 010 16zm-144 0h40a8 8 0 010 16H56a8 8 0 010-16zm136 56h-16a8 8 0 000 16h16a8 8 0 000-16z" />
                    </svg>
                  ),
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center text-center bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300"
                >
                  <div className="text-[#D72638] mb-4">{feature.icon}</div>
                  <h2 className="text-xl font-semibold text-[#1C1D22] mb-2">
                    {feature.title}
                  </h2>
                  <p className="text-gray-500 text-sm">{feature.description}</p>
                </div>
              ))}
            </div>

            {/* Statistics Section */}
            <div className="mt-10 flex justify-evenly">
              <div className="text-center">
                <h3 className="text-3xl font-extrabold text-[#D72638]">500+</h3>
                <p className="text-gray-500">Happy Clients</p>
              </div>
              <div className="text-center">
                <h3 className="text-3xl font-extrabold text-[#D72638]">300+</h3>
                <p className="text-gray-500">Events Decorated</p>
              </div>
              <div className="text-center">
                <h3 className="text-3xl font-extrabold text-[#D72638]">50+</h3>
                <p className="text-gray-500">Exclusive Themes</p>
              </div>
            </div>
            <div className="flex flex-col gap-12 px-6 py-12 bg-[#F9FAFB] rounded-lg">
              {/* FAQ's */}
              <div className="text-center mb-8">
                <h1 className="text-3xl font-extrabold text-[#D72638] mb-4">
                  <FAQ />
                </h1>
                <p className="text-gray-600 max-w-3xl mx-auto text-lg">
                  We deliver extraordinary celebrations with exceptional service
                  and attention to detail.
                </p>
              </div>
            </div>
          </div>
          <footer className="flex justify-center">
            <div className="flex max-w-[960px] flex-1 flex-col">
              <footer className="flex flex-col gap-6 px-5 py-10 text-center @container">
                <div className="flex flex-wrap items-center justify-center gap-6 @[480px]:flex-row @[480px]:justify-around">
                  {[
                    {
                      name: "Our Services",
                      icon: "fas fa-concierge-bell",
                      path: "/",
                    },
                    {
                      name: "Gallery",
                      icon: "fas fa-images",
                      path: "/gallery",
                    },
                    {
                      name: "Testimonials",
                      icon: "fas fa-comments",
                      path: "/",
                    },
                    {
                      name: "Packages & Pricing",
                      icon: "fas fa-tags",
                      path: "/packages",
                    },
                    { name: "FAQs", icon: "fas fa-question-circle", path: "/" },
                    {
                      name: "Contact Us",
                      icon: "fas fa-envelope",
                      path: "/contact",
                    },
                    {
                      name: "Book Now",
                      icon: "fas fa-calendar-check",
                      highlight: true,
                      path: "https://wa.me/+917338011713",
                    },
                  ].map((link, index) => (
                    <Link
                      key={index}
                      className={`text-base font-normal leading-normal min-w-40 transition-colors duration-300 hover:text-red-500 ${
                        link.highlight
                          ? "text-red-500 font-bold"
                          : "text-[#4e7397]"
                      }`}
                      to={link.path}
                      onClick={scrollToTop}
                    >
                      <i className={`${link.icon} mr-2`}></i> {link.name}
                    </Link>
                  ))}
                </div>

                {/* Social Media Links */}
                <div className="flex justify-center gap-4 mt-6">
                  <a
                    href="https://www.facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#4e7397] hover:text-red-500"
                  >
                    <i className="fab fa-facebook-f text-2xl"></i>
                  </a>
                  <a
                    href="https://www.instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#4e7397] hover:text-red-500"
                  >
                    <i className="fab fa-instagram text-2xl"></i>
                  </a>
                  <a
                    href="https://www.twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#4e7397] hover:text-red-500"
                  >
                    <i className="fab fa-twitter text-2xl"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#4e7397] hover:text-red-500"
                  >
                    <i className="fab fa-linkedin-in text-2xl"></i>
                  </a>
                </div>
              </footer>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default NewPage1;
