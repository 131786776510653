import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/testimonal.css"

const testimonials = [
    {
      image: 'https://balloonpro.in/wp-content/uploads/2023/06/test4.webp',
      name: 'Venkat',
      text: 'Good service at reasonable rates. Got a highly customized package based on my requirement. Happy to hire them. excellent work,work will finish on time',
      quoteImg: 'https://balloonpro.in/wp-content/themes/sangpress-2.0/assets/images/quote.webp'
    },
    {
      image: 'https://balloonpro.in/wp-content/uploads/2023/06/test5.webp',
      name: 'Hemanth',
      text: 'Super, One of the Best Event organizers, very enthusiastic n mainly good value for a individuals budget.',
      quoteImg: 'https://balloonpro.in/wp-content/themes/sangpress-2.0/assets/images/quote.webp'
    },
    {
      image: 'https://balloonpro.in/wp-content/uploads/2023/06/test6.webp',
      name: 'VARUN',
      text: 'Excellent work! AN Events has done a fabulous decoration for my shop opening ceremony.Also suggested best packages for the event and made it a grand success',
      quoteImg: 'https://balloonpro.in/wp-content/themes/sangpress-2.0/assets/images/quote.webp'
    },
    {
      image: 'https://balloonpro.in/wp-content/uploads/2023/06/test7.webp',
      name: 'Chetan',
      text: 'Decorations were done beautifully and promptly well before time as per expectation.',
      quoteImg: 'https://balloonpro.in/wp-content/themes/sangpress-2.0/assets/images/quote.webp'
    },
    {
      image: 'https://balloonpro.in/wp-content/uploads/2023/06/test1.webp',
      name: 'Anil Singh Auditor',
      text: 'Good service and pricing,Very professional.I recommend them very much for a reliable service.',
      quoteImg: 'https://balloonpro.in/wp-content/themes/sangpress-2.0/assets/images/quote.webp'
    },
    {
      image: 'https://balloonpro.in/wp-content/uploads/2023/06/test2.webp',
      name: 'Mamtha',
      text: 'The event and decor were great and the event organizer was very polite and suggesting good ideas. Would definitely recommend to all my friends and family.',
      quoteImg: 'https://balloonpro.in/wp-content/themes/sangpress-2.0/assets/images/quote.webp'
    },
    {
      image: 'https://balloonpro.in/wp-content/uploads/2023/06/test3.webp',
      name: 'Sagar',
      text: 'Used their service for my son’s birthday, everyone at home were happy with the decorations. Highly creative and trustworthy people. Delivered what was promised.',
      quoteImg: 'https://balloonpro.in/wp-content/themes/sangpress-2.0/assets/images/quote.webp'
    }
  ];

const TestimonialSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className="testimonial-section">
            <h2>Customer Reviews</h2>
            <Slider {...settings}>
                {testimonials.map((testimonial) => (
                    <div key={testimonial.id} className="testimonial-card">
                        <h4>{testimonial.name}</h4>
                        <p>{testimonial.text}</p>
                           {/* <img src={birthdaypackage.quoteImg} alt="Quote" /> */}
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default TestimonialSlider;
