import React, { useState } from 'react';
import "../css/faq.css";  // Add custom CSS for styling if needed

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqData = [
        {
            question: "What services do you offer?",
            answer: "We offer a wide range of balloon event decor services, from stunning custom decorations tailored to your theme, to intricate balloon arches that make for perfect photo backdrops.            Our expert team also provides comprehensive event planning services, ensuring that every detail of your celebration is handled with care."
        },
        {
            question: "How can I book your services?",
            answer: "You can easily book our services through our user-friendly website, where you can explore our packages, customize your decor preferences, and secure your date in just a few clicks. Alternatively, if you prefer a more personalized touch, feel free to contact us directly at +91 7619233640."
        },
        {
            question: "How will you take my address and other details?",
            answer: "After the payment is completed, a form will open on the website or app asking for your address, balloon color choices, cake flavor, etc., which you can fill out online. If there are any doubts, someone from the team will call you for additional details. You will always have our post-sales number in case you want to discuss something."
        },
        {
            question: "What balloon colors do you have & how can I select the balloon colors?",
            answer: "Decoration will be done as shown in the pictures. If you require a different color balloon combination, please inform us via email or call us at 8081833833."
        },
        {
            question: "When will the decorator reach and how much time will they take?",
            answer: "The decorator will arrive within the selected time slot and complete the booking before your time slot ends. For example, if you choose the time slot of 1 to 4 PM, your decoration will be completed before 4 PM. It typically takes around 45 minutes to 1 hour to decorate the place."
        },
        {
            question: "When & how much surge will be applied?",
            answer: "A 10% surge will be applied for same-day bookings worth less than Rs 3000, and a 5% surge will be applied for bookings worth Rs 3000 or more."
        },
        {
            question: "How many people will come for the decoration?",
            answer: "Generally, only 1 decorator will come to your place. The head decorator's details will be shared with you via email 12 hours in advance."
        },
        {
            question: "Will you send helium gas balloons?",
            answer: "Helium balloons are not part of the base package. Please add them from add-ons if required."
        },
        {
            question: "Why is my wall chipping after decoration?",
            answer: "Walls may chip off if they are newly painted, have excess moisture, or are extremely dry. Additionally, decoration on wallpaper can cause it to peel off."
        },
        {
            question: "How to avoid chipping of walls after decoration?",
            answer: "Take down the balloons immediately after your event is over and carefully pull off the tape. If any residue remains, gently wipe it off the walls with warm, soapy water and a soft cloth."
        },
        {
            question: "Can this be done at midnight?",
            answer: "Our last time slot is from 7 pm to 10 pm, and the decoration will be done within this time slot."
        }
        
        // Add more FAQ items here
    ];

    return (
        
        <div className="faq-container">
            <h2 style={{color:'#D72638'}}>Frequently Asked Questions</h2>
            <div className="faq-list">
                {faqData.map((item, index) => (
                    <div className="faq-item" key={index}>
                        <div className="faq-question" onClick={() => toggleFAQ(index)}>
                            <h4 >{item.question} </h4>
                            <span className="faq-toggle" style={{color:'#D72638'}}>{activeIndex === index ? '-' : '+'}</span>
                        </div>
                        {activeIndex === index && (
                            <div  className="faq-answer">
                                <p style={{color:'#D72638'}}>{item.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
   
    );
};

export default FAQ;
