import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// Your Google Maps API key
const API_KEY = 'AIzaSyCYUqNl1cMzub5ROgZJPiS2VOxH2PA7d-o';

// Map container style
const containerStyle = {
    width: '100%',
    height: '400px',
  };

// Default center location (example: New York City)
const location = {
  lat: 13.056100,
  lng: 77.567800
};

function MyGoogleMap() {
    return (
      <LoadScript googleMapsApiKey={API_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={location}  // Center the map on the pin location
          zoom={12}  // Zoom level
        >
          {/* Add a Marker (Pin) at the specified location */}
          <Marker position={location} />
        </GoogleMap>
      </LoadScript>
    );
  }

export default React.memo(MyGoogleMap);
