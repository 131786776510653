import React, { Fragment, useState } from "react";
import Navigation from "./navigation";
import Footer from "./footer";
import "../css/baloon.css";
import { Link } from "react-router-dom";
import { birthdaypackages } from "../data/birthdaydata";

function IndexBirthday() {
  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const [currentPage, setCurrentPage] = useState(1);
  const packagesPerPage = 4; // Number of packages per page

  // Calculate total pages
  const totalPages = Math.ceil(birthdaypackages.length / packagesPerPage);

  // Get current packages for the page
  const indexOfLastPackage = currentPage * packagesPerPage;
  const indexOfFirstPackage = indexOfLastPackage - packagesPerPage;
  const currentPackages = birthdaypackages.slice(indexOfFirstPackage, indexOfLastPackage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    window.scrollTo(0, 0);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
        <h2>Birthday Packages</h2>
          <section className="home-blog-area section-padding30">
            <div className="container">
              <div className="row">
                {currentPackages.map((birthdaypackage, index) => (
                  <div className="col-xl-6 col-lg-6 col-md-6" key={index}>
                    <div className="home-blog-single mb-30">
                      <div className="blog-img-cap">
                        <div className="blog-img">
                          <img src={`/${birthdaypackage.image}`} alt="" />
                          <div className="blog-date text-center">
                            <p>Rs.{birthdaypackage.price}/-</p>
                          </div>
                        </div>
                        <div className="blog-cap">
                          <h3>{birthdaypackage.name}</h3>
                          <p>{birthdaypackage.text}</p>
                          <Link
                            to={{
                              pathname: '/details',
                              search: `?id=${birthdaypackage.id}&name=${encodeURIComponent(birthdaypackage.name)}`,
                              state: { package: birthdaypackage }, // Pass additional data via state if needed
                            }}
                            onClick={scrollToTop}
                            className="btn hero-btn hero_btn_main"
                            data-animation="fadeInLeft"
                            data-delay="1.0s"
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="pagination">
                <button
                  className="pagination-button"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className="pagination-button"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </section>
      <style jsx>{`
        .pagination {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
        }

        .pagination-button {
          background-color: #e6005c;
          color: white;
          border: none;
          border-radius: 4px;
          padding: 10px 20px;
          margin: 0 5px;
          cursor: pointer;
          font-size: 16px;
          transition: background-color 0.3s ease;
        }

        .pagination-button:hover {
          background-color: white;
          color: #e6005c;
        }

        .pagination-button:disabled {
          background-color: #c0c0c0;
          cursor: not-allowed;
        }

        .pagination-info {
          margin: 0 15px;
          font-size: 16px;
        }
      `}</style>
    </Fragment>
  );
}

export default IndexBirthday;

