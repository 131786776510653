import React, { Fragment, useState } from "react";
import Navigation from "./navigation";
import Footer from "./footer";
import "../css/baloon.css";
import { Link } from "react-router-dom";
import { birthdaypackages } from "../data/birthdaydata";
import backgroundImage from "../images/DALL·E-2024-11-09-10.16.jpeg";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function Balloon() {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const [currentPage, setCurrentPage] = useState(1);
  const packagesPerPage = 6; // Number of packages per page

  // Calculate total pages
  const totalPages = Math.ceil(birthdaypackages.length / packagesPerPage);

  // Get current packages for the page
  const indexOfLastPackage = currentPage * packagesPerPage;
  const indexOfFirstPackage = indexOfLastPackage - packagesPerPage;
  const currentPackages = birthdaypackages.slice(indexOfFirstPackage, indexOfLastPackage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    window.scrollTo(0, 0);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
       <Helmet>
                <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="" />
                <link
                    rel="stylesheet"
                    as="style"
                    onLoad="this.rel='stylesheet'"
                    href="https://fonts.googleapis.com/css2?display=swap&amp;family=Manrope%3Awght%40400%3B500%3B700%3B800&amp;family=Noto+Sans%3Awght%40400%3B500%3B700%3B900"
                />
                <title>Galileo Design</title>
                <link rel="icon" type="image/x-icon" href="data:image/x-icon;base64," />
                <script src="https://cdn.tailwindcss.com?plugins=forms,container-queries"></script>
            </Helmet>
      <div>
        <header style={{ height: "93px" }}>
          <Navigation />
        </header>
        <main>
          <a
            href="https://wa.me/+917619233640"
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-whatsapp whatsapp-icon"></i>
          </a>

          <a
            href="tel:${+917619233640}"
            className="phone_float"
            rel="noopener noreferrer"
          >
            <i className="fa fa-phone phone-icon"></i>
          </a>
          {/* <div className="slider-area2">
            <div className="slider-height2 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="hero-cap hero-cap2 text-center">
                      <h2>Birthday Packages</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


{/* Main Section */}
<div className="px-10 flex flex-1 justify-center py-16 relative bg-gradient-to-r from-yellow-400 to-pink-400 text-white overflow-hidden">
  {/* Floating Decorations */}
  <div className="absolute top-0 left-0 w-24 h-24 rounded-full bg-pink-500 opacity-50 transform -translate-x-1/2 -translate-y-1/2"></div>
  <div className="absolute bottom-0 right-0 w-32 h-32 rounded-full bg-yellow-300 opacity-60 transform translate-x-1/2 translate-y-1/2"></div>

  {/* Content Container */}
  <div className="layout-content-container flex flex-col max-w-[960px] flex-1 items-center text-center">
    <h1 className="text-5xl font-bold mb-4 transform rotate-[-3deg] drop-shadow-md">🎉 Birthday Celebration Package 🎉</h1>
    <h2 className="text-lg font-medium max-w-[600px] mb-8 drop-shadow-sm">
      Experience the joy of your birthday with a splash of colors and decorations that will make your special day unforgettable!
    </h2>
    <div
      className="w-full max-w-[600px] bg-center bg-no-repeat aspect-video bg-cover rounded-3xl mb-8 shadow-lg transform rotate-[2deg] hover:rotate-0 hover:scale-105 transition-all duration-500"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    ></div>
    <button className="bg-[#ffb700] hover:bg-yellow-500 text-white py-3 px-8 rounded-full font-bold shadow-lg transform hover:scale-105 transition duration-300">
      <Link onClick={scrollToTop} to="/birthday">Discover More</Link>
    </button>
  </div>
</div>

{/* Birthday Packages Section */}
<div className="py-16 bg-[#f9fafb] text-center">
  <h2 className="text-3xl font-bold text-[#0e141b] mb-8">Our Birthday Packages</h2>
  <p className="text-lg text-[#4e7397] mb-12 mx-auto max-w-3xl">
    Whether you're planning an intimate gathering or a grand celebration, our birthday packages are designed to bring your vision to life. From personalized themes to elegant decorations, we’ve got something for every occasion!
  </p>

  <div className="w-full bg-gradient-to-r from-yellow-400 to-pink-400 rounded-lg shadow-lg p-12 overflow-hidden relative">
    {/* Floating Decorations */}
    <div className="absolute top-0 right-0 w-20 h-20 rounded-full bg-pink-500 opacity-40 transform translate-x-1/3 -translate-y-1/3"></div>
    <div className="absolute bottom-0 left-0 w-32 h-32 rounded-full bg-yellow-300 opacity-50 transform -translate-x-1/2 translate-y-1/2"></div>

    <div className="max-w-6xl mx-auto text-center mb-12">
      <h1 className="text-4xl font-extrabold text-white mb-6 transform rotate-[-2deg]">
        Birthday Celebrations 🎉
      </h1>
      <p className="text-lg text-white max-w-4xl mx-auto mb-10">
        Make birthdays unforgettable! Choose the perfect package to match the age and theme of the celebration.
      </p>
    </div>

    {/* Package Cards */}
    <div className="flex flex-wrap gap-10 justify-center">
      {/* Kids Birthday */}
      <div className="w-[250px] h-[350px] bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105">
        <div
          className="w-full h-40 bg-cover bg-center rounded-lg mb-4"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>
        <h2 className="text-2xl font-bold text-gray-800 mb-2">Kids Birthday</h2>
        <p className="text-sm text-gray-600">
          Fun, games, and colorful decorations to make your kid's birthday extra special.
        </p>
      </div>
      {/* Teen Birthday */}
      <div className="w-[250px] h-[350px] bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105">
        <div
          className="w-full h-40 bg-cover bg-center rounded-lg mb-4"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>
        <h2 className="text-2xl font-bold text-gray-800 mb-2">Teen Birthday</h2>
        <p className="text-sm text-gray-600">
          Celebrate with a theme your teen will love – from stylish decor to exciting party games.
        </p>
      </div>
      {/* Adult Birthday */}
      <div className="w-[250px] h-[350px] bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105">
        <div
          className="w-full h-40 bg-cover bg-center rounded-lg mb-4"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>
        <h2 className="text-2xl font-bold text-gray-800 mb-2">Adult Birthday</h2>
        <p className="text-sm text-gray-600">
          Elegant setups for adults – sophisticated themes and unforgettable experiences.
        </p>
      </div>
    </div>
  </div>
</div>



          {/* Birthday Packages Section */}
<div className="py-16 bg-[#f9fafb] text-center">
    <h2 className="text-3xl font-bold text-[#0e141b] mb-8">Our Birthday Packages</h2>
    <p className="text-lg text-[#4e7397] mb-12 mx-auto max-w-3xl">
        Whether you're planning an intimate gathering or a grand celebration, our birthday packages are designed to bring your vision to life. From personalized themes to elegant decorations, we’ve got something for every occasion!
    </p>

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
        {/* Classic Birthday Package */}
        <div className="bg-white p-8 rounded-lg shadow-xl transition-transform transform hover:scale-105">
            <h3 className="text-2xl font-semibold text-[#0e141b] mb-4">Classic Birthday Package</h3>
            <p className="text-sm text-[#4e7397] mb-6">
                A timeless, elegant decoration set for a perfect birthday celebration. Enjoy customized color schemes, balloon arches, and personalized table settings.
            </p>
            <ul className="text-left text-sm text-[#4e7397] mb-6">
                <li>Balloon Arch</li>
                <li>Customized Balloon Bouquet</li>
                <li>Table and Chair Decorations</li>
            </ul>
            <button className="w-full bg-red-500 text-white py-3 rounded-full font-semibold shadow-md transition-all duration-300 transform hover:bg-red-600">
            <Link  to="https://wa.me/+917338011713">Book Now </Link>
            </button>
        </div>

        {/* Premium Birthday Package */}
        <div className="bg-white p-8 rounded-lg shadow-xl transition-transform transform hover:scale-105">
            <h3 className="text-2xl font-semibold text-[#0e141b] mb-4">Premium Birthday Package</h3>
            <p className="text-sm text-[#4e7397] mb-6">
                Elevate your celebration with a premium package that includes a unique balloon backdrop, custom party favors, and exquisite table centerpieces.
            </p>
            <ul className="text-left text-sm text-[#4e7397] mb-6">
                <li>Themed Balloon Backdrop</li>
                <li>Custom Party Favors</li>
                <li>Decorative Table Centerpieces</li>
            </ul>
            <button className="w-full bg-red-500 text-white py-3 rounded-full font-semibold shadow-md transition-all duration-300 transform hover:bg-red-600">
            <Link  to="https://wa.me/+917338011713">Book Now </Link>
            </button>
        </div>

        {/* Deluxe Birthday Package */}
        <div className="bg-white p-8 rounded-lg shadow-xl transition-transform transform hover:scale-105">
            <h3 className="text-2xl font-semibold text-[#0e141b] mb-4">Deluxe Birthday Package</h3>
            <p className="text-sm text-[#4e7397] mb-6">
                Our Deluxe package offers a luxurious experience with a grand balloon arch, full venue decor, and a customized photo booth area for unforgettable memories.
            </p>
            <ul className="text-left text-sm text-[#4e7397] mb-6">
                <li>Grand Balloon Arch</li>
                <li>Full Venue Decoration</li>
                <li>Personalized Photo Booth</li>
            </ul>
            <button className="w-full bg-red-500 text-white py-3 rounded-full font-semibold shadow-md transition-all duration-300 transform hover:bg-red-600">
            <Link  to="https://wa.me/+917338011713">Book Now </Link>
            </button>
        </div>
    </div>
</div>


<section className="birthday-packages-section py-20 bg-gray-100">
  <div className="container mx-auto px-4 lg:px-16 space-y-24">

    {/* Section Header */}
    <div className="text-center mb-12">
      <h2 className="text-4xl font-extrabold text-black mb-8">Birthday Celebration Packages 🎉</h2>
      <p className="text-lg text-gray-700 max-w-3xl mx-auto">
        Celebrate your special day with one of our amazing packages! From simple gatherings to extravagant celebrations, we have something for everyone.
      </p>
    </div>

    {/* Packages Listing */}
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
      {currentPackages.map((birthdaypackage, index) => (
        <div key={index} className="relative group rounded-2xl shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-500 transform hover:scale-105 bg-white">

          {/* Background Accent Elements */}
          <div className={`absolute -z-10 w-80 h-80 rounded-full blur-3xl opacity-20 transform ${index % 2 === 0 ? '-top-10 -left-10' : '-bottom-10 -right-10'} bg-gradient-to-r from-red-200 to-transparent`}></div>

          {/* Package Image */}
          <div className="w-full h-[350px] overflow-hidden relative">
            <img
              src={`/${birthdaypackage.image}`}
              alt={birthdaypackage.name}
              className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
            />
            <div className="absolute top-4 left-4 bg-red-600 text-white text-sm font-bold px-4 py-2 rounded-lg shadow-md group-hover:bg-red-700 transition-all duration-300">
              Rs. {birthdaypackage.price}/-
            </div>
          </div>

          {/* Package Content */}
          <div className="p-8">
            <h3 className="text-3xl font-bold text-black mb-4">{birthdaypackage.name}</h3>
            <p className="text-md text-gray-700 mb-4">{birthdaypackage.text}</p>
            <ul className="text-sm text-gray-600 space-y-2 mb-6">
              <li className="flex items-center">
                <span className="inline-block w-3 h-3 bg-red-600 rounded-full mr-3"></span>
                Customized decorations to make your day special
              </li>
              <li className="flex items-center">
                <span className="inline-block w-3 h-3 bg-red-600 rounded-full mr-3"></span>
                Unique themes tailored for different ages
              </li>
              <li className="flex items-center">
                <span className="inline-block w-3 h-3 bg-red-600 rounded-full mr-3"></span>
                Entertainment options for all guests
              </li>
            </ul>

            {/* Actions */}
            <div className="flex gap-4">
              <Link
                to={{
                  pathname: '/details',
                  search: `?id=${birthdaypackage.id}&name=${encodeURIComponent(birthdaypackage.name)}`,
                  state: { package: birthdaypackage },
                }}
                onClick={scrollToTop}
                className="inline-block px-6 py-3 text-white font-bold bg-red-600 rounded-full shadow-md hover:bg-red-700 transition-transform transform hover:scale-110"
              >
                View Details
              </Link>
              <button
                className="inline-block px-6 py-3 text-red-600 font-bold border-2 border-red-600 rounded-full shadow-md hover:bg-red-600 hover:text-white transition-transform transform hover:scale-110"
                onClick={() => navigate(`/booking?id=${birthdaypackage.id}`)}
              >
                   <Link  to="https://wa.me/+917338011713">Book Now </Link>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>

    {/* Pagination Section */}
    <div className="pagination flex items-center justify-center mt-20 space-x-4">
      <button
        className="pagination-button bg-red-600 text-white py-3 px-6 rounded-full hover:bg-red-700 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300"
        onClick={handlePrevPage}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span className="text-gray-700 font-semibold">
        Page {currentPage} of {totalPages}
      </span>
      <button
        className="pagination-button bg-red-600 text-white py-3 px-6 rounded-full hover:bg-red-700 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300"
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  </div>
</section>


        </main>
        <Footer />
      </div>
      <style jsx>{`
        .pagination {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
        }

        .pagination-button {
          background-color: #e6005c;
          color: white;
          border: none;
          border-radius: 4px;
          padding: 10px 20px;
          margin: 0 5px;
          cursor: pointer;
          font-size: 16px;
          transition: background-color 0.3s ease;
        }

        .pagination-button:hover {
          background-color: white;
          color: #e6005c;
        }

        .pagination-button:disabled {
          background-color: #c0c0c0;
          cursor: not-allowed;
        }

        .pagination-info {
          margin: 0 15px;
          font-size: 16px;
        }
      `}</style>
    </Fragment>
  );
}

export default Balloon;
