import React, { Fragment, useState } from "react";
import Navigation from "./navigation";
import Footer from "./footer";
import { Link } from "react-router-dom";
import "../css/packages.css";
import NewPage1 from "./NewPage1";
import NewPage3 from "./NewPage3";
import FunActivities from "./funActivities";

function Packages() {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  // Define package data
  const packages = [
    {
      id: 1,
      title: "BIRTHDAY PACKAGES",
      category: "Birthday Packages",
      description: "Make your loved one’s birthday unforgettable with our unique balloon arrangements...",
      imgSrc: "assets/event/packages/birthday.jpg",
      url: "/birthday",
      price: '1000-2000'
    },
    {
      id: 2,
      title: "WEDDING PACKAGES",
      category: "Wedding Packages",
      description: "Make your wedding day unforgettable with our elegant arrangements...",
      imgSrc: "assets/event/packages/reception1.jpg",
       url: "/wedding",
       price: '1500-2000'
    },
    {
      id: 3,
      title: "BABY SHOWER PACKAGES",
      category: "Baby Shower Packages",
      description: "Make your baby shower truly special with our enchanting arrangements...",
      imgSrc: "assets/event/packages/babys.jpg",
      url: "/babyshower",
      price: '10000-20000'
    },
    // {
    //   id: 4,
    //   title: "FUN & ENTERTAINMENT PACKAGES",
    //   category: "Fun & Entertainment",
    //   description: "Elevate your event with our exciting fun and entertainment packages...",
    //   imgSrc: "assets/event/packages/fun.jpg",
    //   url: "/Fun&Entertainment",
    //   price: '1500-2000'
    // },
  ];

  // Filter packages based on search query and selected category
  const filteredPackages = packages.filter(
    (pkg) =>
      (selectedCategory === "" || pkg.category === selectedCategory) &&
      pkg.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate counts for each category
  const getCategoryCount = (category) => {
    return packages.filter((pkg) => pkg.category === category).length;
  };

  const handleCategoryClick = (event, category) => {
    event.preventDefault(); // Prevent page refresh
    setSelectedCategory(category);
    setSearchQuery(""); // Clear the search query when changing category
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent form submission refresh
  };

  const clearSearch = () => {
    setSearchQuery(""); // Clear the search query
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
};

  return (
    <Fragment>
      <div>
        <header style={{ height: "93px" }}>
          <Navigation />
        </header>
        <main>
          <a
            href="https://wa.me/+917619233640"
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-whatsapp whatsapp-icon"></i>
          </a>

          <a
            href="tel:+917619233640"
            className="phone_float"
            rel="noopener noreferrer"
          >
            <i className="fa fa-phone phone-icon"></i>
          </a>
          
          <div>
          
          <NewPage1 />
         </div>
          {/* <section className="blog_area section-padding">
            <div className="container container-1">
              <div className="row row-1">
                <div className="col-lg-4 fixed-sidebar">
                  <div className="blog_right_sidebar">
                    <aside className="searchpad single_sidebar_widget search_widget">
                      <form onSubmit={handleSearchSubmit}>
                        <div className="form-group">
                          <div className="input-group mb-3">
                            <input
                              className="form-control"
                              placeholder="Search Keyword"
                              type="text"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <div className="input-group-append">
                              <button
                                className="btn-clear"
                                type="button"
                                onClick={clearSearch}
                              >
                                <i className="ti-close" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </aside>
                    <aside className="single_sidebar_widget post_category_widget">
                      <h4 className="widget_title">Category</h4>
                      <ul className="list cat-list">
                        <div className="filter-grid">
                          <li>
                            <a
                              className={`d-flex ${selectedCategory === "" ? "active" : ""}`}
                              href="#"
                              onClick={(e) => handleCategoryClick(e, "")}
                            >
                              <p>All Packages</p>
                              <p>({packages.length})</p>
                            </a>
                          </li>
                          <hr /> 
                          <li>
                            <Link
                              className={`d-flex ${selectedCategory === "Birthday Packages" ? "active" : ""}`}
                              to="/birthday"
                              onClick={(e) => handleCategoryClick(e, "Birthday Packages")}
                            >
                              <p>Birthday Packages</p>
                              <p>({getCategoryCount("Birthday Packages")})</p>
                            </Link>
                          </li>
                          <li>
                            <a
                              className={`d-flex ${selectedCategory === "Wedding Packages" ? "active" : ""}`}
                              href="#"
                              onClick={(e) => handleCategoryClick(e, "Wedding Packages")}
                            >
                              <p>Wedding Packages</p>
                              <p>({getCategoryCount("Wedding Packages")})</p>
                            </a>
                          </li>
                          <li>
                            <a
                              className={`d-flex ${selectedCategory === "Baby Shower Packages" ? "active" : ""}`}
                              href="#"
                              onClick={(e) => handleCategoryClick(e, "Baby Shower Packages")}
                            >
                              <p>Baby Shower Packages</p>
                              <p>({getCategoryCount("Baby Shower Packages")})</p>
                            </a>
                          </li>
                          
                        </div>
                      </ul>
                    </aside>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="blog_left_sidebar">
                    {filteredPackages.length > 0 ? (
                      filteredPackages.map((pkg) => (
                        <article key={pkg.id} className="blog_item">
                          <div className="blog_item_img">
                            <img
                              alt=""
                              className="card-img rounded-0"
                              src={pkg.imgSrc}
                            />
                          </div>
                          <div className="blog_details">
                            <a className="d-inline-block" href="blog_details.html">
                              <h2 className="blog-head">{pkg.title}</h2>
                            </a>
                            <p>{pkg.description}</p>
                            <ul className="blog-info-link">
                              <li>
                                <strong>
                                  <a href="#">
                                    <i className="fa fa-inr" /> {pkg.price}
                                  </a>
                                </strong>
                              </li>
                            </ul>
                            <Link className="btn_pck" to={pkg.url} onClick={scrollToTop}>
                              View All
                            </Link>
                          </div>
                        </article>
                      ))
                    ) : (
                      <div className="not-found">
                        <h3>No Packages Found</h3>
                        <p>Sorry, we couldn't find any packages that match your search criteria.</p>
                        <div className="category-buttons">
                          <button
                            className={`category-button ${selectedCategory === "Birthday Packages" ? "active" : ""}`}
                            onClick={(e) => handleCategoryClick(e, "Birthday Packages")}
                          >
                            Birthday Packages
                          </button>
                          <button
                            className={`category-button ${selectedCategory === "Wedding Packages" ? "active" : ""}`}
                            onClick={(e) => handleCategoryClick(e, "Wedding Packages")}
                          >
                            Wedding Packages
                          </button>
                          <button
                            className={`category-button ${selectedCategory === "Baby Shower Packages" ? "active" : ""}`}
                            onClick={(e) => handleCategoryClick(e, "Baby Shower Packages")}
                          >
                            Baby Shower Packages
                          </button>
                          <button
                            className={`category-button ${selectedCategory === "Fun & Entertainment" ? "active" : ""}`}
                            onClick={(e) => handleCategoryClick(e, "Fun & Entertainment")}
                          >
                            Fun & Entertainment
                          </button>
                          <button
                            className={`category-button ${selectedCategory === "" ? "active" : ""}`}
                            onClick={(e) => handleCategoryClick(e, "")}
                          >
                            All Packages
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </main>
        <footer>
          <Footer />
        </footer>
        <div id="back-top">
          <a href="#" title="Go to Top">
            <i className="fas fa-level-up-alt" />
          </a>
        </div>
      </div>
    </Fragment>
  );
}

export default Packages;
