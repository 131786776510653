export const weddingpackages = [
  {
    "id": 1,
    "image": "assets/event/Wedding/wb1.jpg", // Replace with the actual image path
    "name": "Traditional Indian Wedding Mandap Decor",
    "text": "A classic Indian wedding mandap adorned with vibrant red and yellow drapes, floral arrangements, and a carpeted base for a traditional and elegant ceremony.",
    "price": "5000", // Adjust based on actual pricing
    "inclusions": [
      {
        "include": "Red and yellow fabric drapes for the mandap structure"
      },
      {
        "include": "Floral arrangements with colorful flowers on the mandap pillars and overhead"
      },
      {
        "include": "A carpeted base for the mandap"
      },
      {
        "include": "Additional decorative elements like fairy lights or lanterns"
      }
    ],
    "aboutExperience": "Create a timeless and beautiful atmosphere for your Indian wedding with this traditional mandap decor. The vibrant colors and elegant design will create a memorable experience for you and your guests."
  },
  {
    "id": 2,
    "image": "assets/event/Wedding/wb2.jpg", // Replace with the actual image path
    "name": "Grand Wedding Stage Decor",
    "text": "A luxurious wedding stage featuring a floral arch, a plush seating area, and elegant lighting for a sophisticated and glamorous event.",
    "price": "8000", // Adjust based on actual pricing
    "inclusions": [
      {
        "include": "Floral arch with cascading flowers and greenery"
      },
      {
        "include": "A plush seating area for the bride and groom"
      },
      {
        "include": "Elegant lighting fixtures, including chandeliers and spotlights"
      },
      {
        "include": "Customizable backdrop with a monogram or design"
      }
    ],
    "aboutExperience": "Create a stunning and unforgettable wedding stage with this luxurious decor. The elegant design and high-quality materials will make your special day truly memorable."
  },
  {
    "id": 3,
    "image": "assets/event/Wedding/wb3.jpg", // Replace with the actual image path
    "name": "Traditional Indian Wedding Stage with Chandeliers",
    "text": "A classic Indian wedding stage with a teal backdrop, floral garlands, and elegant chandeliers for a sophisticated and traditional event.",
    "price": "7000", // Adjust based on actual pricing
    "inclusions": [
      {
        "include": "Teal backdrop with a diamond pattern"
      },
      {
        "include": "Floral garlands adorning the stage and chairs"
      },
      {
        "include": "Elegant chandeliers hanging from the ceiling"
      },
      {
        "include": "A plush seating area for the bride and groom"
      }
    ],
    "aboutExperience": "Create a timeless and beautiful atmosphere for your Indian wedding with this traditional stage decor. The elegant design and high-quality materials will make your special day truly memorable."
  },
  {
    "id": 4,
    "image": "assets/event/Wedding/wb6.jpg", // Replace with the actual image path
    "name": "Lotus-Inspired Wedding Stage Decor",
    "text": "A beautiful wedding stage featuring a lotus flower-shaped backdrop, floral arrangements, and a gold-toned seating area for a traditional and elegant event.",
    "price": "7500", // Adjust based on actual pricing
    "inclusions": [
      {
        "include": "Lotus flower-shaped backdrop with intricate designs"
      },
      {
        "include": "Floral arrangements with white roses and greenery"
      },
      {
        "include": "A gold-toned seating area for the bride and groom"
      },
      {
        "include": "Elegant lighting fixtures, including spotlights"
      }
    ],
    "aboutExperience": "Create a unique and beautiful wedding stage with this lotus-inspired decor. The elegant design and high-quality materials will make your special day truly memorable."
  },{
    "id": 5,
    "image": "assets/event/Wedding/wb7.jpg", // Replace with the actual image path
    "name": "Romantic Wedding Stage with Circular Floral Arrangements",
    "text": "A romantic wedding stage featuring circular floral arrangements, a pink backdrop, and elegant lighting for a sophisticated and intimate event.",
    "price": "6500", // Adjust based on actual pricing
    "inclusions": [
      {
        "include": "Circular floral arrangements with roses and greenery"
      },
      {
        "include": "Pink backdrop with draped fabric"
      },
      {
        "include": "A plush seating area for the bride and groom"
      },
      {
        "include": "Elegant lighting fixtures, including spotlights"
      }
    ],
    "aboutExperience": "Create a romantic and intimate wedding stage with this elegant decor. The unique design and high-quality materials will make your special day truly memorable."
  }
  ,{
    "id": 6,
    "image": "assets/event/Wedding/wb12.jpg", // Replace with the actual image path
    "name": "Modern Wedding Stage with Rustic Elements",
    "text": "A contemporary wedding stage featuring a rustic wooden frame, floral arrangements, and elegant lighting for a stylish and intimate event.",
    "price": "6000", // Adjust based on actual pricing
    "inclusions": [
      {
        "include": "Rustic wooden frame with fairy lights"
      },
      {
        "include": "Floral arrangements with greenery and white flowers"
      },
      {
        "include": "A plush white sofa for the bride and groom"
      },
      {
        "include": "Elegant lighting fixtures, including chandeliers and spotlights"
      }
    ],
    "aboutExperience": "Create a modern and stylish wedding stage with this rustic-inspired decor. The unique design and high-quality materials will make your special day truly memorable."
  }, 
  {
    "id": 7,
    "image": "assets/event/Wedding/wb13.jpg", // Replace with the actual image path
    "name": "Mehendi Entrance Decor with Floral Arch",
    "text": "A vibrant mehendi entrance featuring a floral arch, a welcome banner, and decorative elements for a festive and welcoming atmosphere.",
    "price": "3000", // Adjust based on actual pricing
    "inclusions": [
      {
        "include": "Floral arch with yellow and white flowers"
      },
      {
        "include": "Welcome banner with the couple's names"
      },
      {
        "include": "Decorative elements like hanging beads and potted plants"
      }
    ],
    "aboutExperience": "Create a festive and welcoming atmosphere for your mehendi ceremony with this vibrant entrance decor. The colorful floral arrangements and personalized banner will make your guests feel special."
  },{
    "id": 8,
    "image": "assets/event/Wedding/wb14.jpg", // Replace with the actual image path
    "name": "Grand Wedding Entrance Decor",
    "text": "A stunning wedding entrance featuring floral arrangements, pink drapes, and elegant lighting for a grand and memorable event.",
    "price": "5500", // Adjust based on actual pricing
    "inclusions": [
      {
        "include": "Floral arrangements with roses and greenery"
      },
      {
        "include": "Pink drapes framing the entrance"
      },
      {
        "include": "Elegant lighting fixtures, including hanging chandeliers"
      },
      {
        "include": "Decorative elements like potted plants and wooden boxes"
      }
    ],
    "aboutExperience": "Create a grand and memorable entrance for your wedding with this stunning decor. The elegant design and high-quality materials will make a lasting impression on your guests."
  },{
    "id": 9,
    "image": "assets/event/Wedding/wb15.jpg", // Replace with the actual image path
    "name": "Grand Wedding Aisle Decor",
    "text": "A stunning wedding aisle featuring floral arches, candlelit pathways, and elegant lighting for a grand and memorable entrance.",
    "price": "7000", // Adjust based on actual pricing
    "inclusions": [
      {
        "include": "Floral arches framing the aisle"
      },
      {
        "include": "Candlelit pathways leading to the stage"
      },
      {
        "include": "Elegant lighting fixtures, including chandeliers and spotlights"
      },
      {
        "include": "Decorative elements like greenery and foliage"
      }
    ],
    "aboutExperience": "Create a grand and memorable entrance for your wedding with this stunning aisle decor. The elegant design and high-quality materials will make a lasting impression on your guests."
  }
  ];