import React, { Fragment,useState } from "react";
import Navigation from "./navigation";
import Footer from "./footer";
import MyGoogleMap from "./MyGoogleMap";

function Contact() {
  const [formData, setFormData] = useState({
    message: '',
    name: '',
    email: '',
    selectedPackage: '', 
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSmsClick = () => {
    const phoneNumber = "+917338011713"; // Replace with the desired phone number
    const smsMessage = `Hello, I'm ${formData.name}. I'm interested in the ${formData.selectedPackage} package and would love to learn more about it. Could you please provide me with additional details and any special offers that might be available? I appreciate your assistance and look forward to hearing from you soon. Thank you!`;
    const smsLink = `sms:${phoneNumber}?body=${encodeURIComponent(smsMessage)}`;
    window.location.href = smsLink;
  };
  
  return (
    <Fragment>
      <div>
        <header style={{height:'93px'}}>
         <Navigation />
        </header>
        <main>
          <a
            href="https://wa.me/+917338011713"
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa fa-whatsapp whatsapp-icon"></i>
          </a>

          <a
            href="tel:${+917338011713}"
            className="phone_float"
            rel="noopener noreferrer"
          >
            <i class="fa fa-phone phone-icon"></i>
          </a>
          <div className="slider-area2">
            <div className="slider-height2 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="hero-cap hero-cap2 text-center">
                      <h2>Contact Us</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="contact-section">
            <div className="container">  
              <div className="row">
                <div className="col-12">
                  <h2 className="contact-title">Get in Touch</h2>
                </div>
                <div className="col-lg-8">
      <form className="form-contact contact_form" id="contactForm" noValidate>
        <div className="row">
        <div className="col-12">
          <div className="form-group">
            <select
              className="form-control"
              id="selectedPackage"
              name="selectedPackage"
              value={formData.selectedPackage}
              onChange={handleChange}
            >
              <option value="">Select a package</option>
              <option value="Baloon Decoration">Baloon Decoration</option>
              <option value="Birthday Party">Birthday Party</option>
              <option value="Flower Decoration">Flower Decoration</option>
              <option value="wedding and Recption">Wedding and Recption</option>
              <option value="Entertainment and Fun activities">Entertainment and Fun activities</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
          
          <div className="col-sm-6">
            <div className="form-group">
              <input
                className="form-control valid"
                id="name"
                name="name"
                placeholder="Enter your name"
                type="text"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <input
                className="form-control valid"
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
          </div>
            <div className="col-12">
            <div className="form-group">
              <textarea
                className="form-control w-100"
                cols="30"
                id="message"
                name="message"
                placeholder="Enter Message"
                rows="9"
                value={formData.message}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="form-group mt-3">
          <button
            className="button button-contactForm boxed-btn"
            type="button"
            onClick={handleSmsClick}
          >
            Send via SMS
          </button>
        </div>
      </form>
    </div>
                {/* <div className="col-lg-8">
                  <form
                    action="contact_process.php"
                    className="form-contact contact_form"
                    id="contactForm"
                    method="post"
                    noValidate
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            className="form-control w-100"
                            cols="30"
                            id="message"
                            name="message"
                            onblur="this.placeholder = 'Enter Message'"
                            onfocus="this.placeholder = ''"
                            placeholder=" Enter Message"
                            rows="9"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            className="form-control valid"
                            id="name"
                            name="name"
                            onblur="this.placeholder = 'Enter your name'"
                            onfocus="this.placeholder = ''"
                            placeholder="Enter your name"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            className="form-control valid"
                            id="email"
                            name="email"
                            onblur="this.placeholder = 'Enter email address'"
                            onfocus="this.placeholder = ''"
                            placeholder="Email"
                            type="email"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            className="form-control"
                            id="subject"
                            name="subject"
                            onblur="this.placeholder = 'Enter Subject'"
                            onfocus="this.placeholder = ''"
                            placeholder="Enter Subject"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <button
                        className="button button-contactForm boxed-btn"
                        type="submit"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div> */}
                  <div className="col-lg-3 offset-lg-1">
                  <div className="media contact-info">
                    <span className="contact-info__icon">
                      <i className="ti-home" />
                    </span>
                    <div className="media-body">
                      <h3>Bangalore, Karnataka.</h3>
                      <p>Karnataka, KA 560097</p>
                    </div>
                  </div>
                  <div className="media contact-info">
                    <span className="contact-info__icon">
                      <i className="ti-tablet" />
                    </span>
                    <div className="media-body">
                      <h3>+917338011713</h3>
                      <p>Sun to Sat 9am to 6pm</p>
                    </div>
                  </div>
                  <div className="media contact-info">
                    <span className="contact-info__icon">
                      <i className="fa fa-whatsapp" />
                    </span>
                    <div className="media-body">
                      <h3>+917338011713</h3>
                      <p>Text us anytime</p>
                    </div>
                  </div>
                  <div className="media contact-info">
                    <span className="contact-info__icon">
                      <i className="ti-email" />
                    </span>
                    <div className="media-body">
                      <h3>vrvvinodmani@gmail.com</h3>
                      <p>Send us your query anytime!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MyGoogleMap />
          </section>
        </main>
        <footer>
        <Footer />
        </footer>
        <div id="back-top">
          <a href="#" title="Go to Top">
            {" "}
            <i className="fas fa-level-up-alt" />
          </a>
        </div>
      </div>
    </Fragment>
  );
}
export default Contact;
