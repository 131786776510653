import React from 'react';
import { Helmet } from 'react-helmet';

const NewPage = () => {
  const birthdayPackages = [
    {
      id: 1,
      image: 'assets/event/Birthday/bb1.jpg',
      name: 'Aviation theme Birthday Decor',
      text: 'Featuring a balloon arch, airplane, neon signs, and vibrant pastel balloons for a whimsical celebration Decoration Theme',
      price: '2000',
      inclusions: [
        { include: 'Balloon Arch of 150 balloons in Red, Blue, White, and Gray colors, arranged in clusters to create a cloud-like effect.' },
        { include: 'Paper Clouds attached to the backdrop and surrounding the arch.' },
        { include: 'Bubble Balloons placed within the arch.' },
        { include: 'Airplane toy prop suspended amidst the balloon clusters.' },
        { include: 'Cloud-shaped Sunboard cutouts (6x4ft) as backdrop panels.' },
        { include: 'Neon "Happy Birthday" sign and illuminated number "5".' },
        { include: '4 18-inch Big pastel balloons in various colors.' },
        { include: 'Transparent cubes with "BAB" letters filled with balloons.' },
      ],
      aboutExperience: 'Indulge in the enchantment of the cutest baby shower decorations you have ever seen! Get ready to be swept away by our dreamy and captivating sky baby shower decoration theme. We have carefully curated every element to ensure a truly magical experience for you and your loved ones. Picture yourself amidst this breathtaking decoration, surrounded by the warmth of family and friends. Its an incredible sight that will fill your heart with joy and excitement.',
    },
    {
      id: 2,
      image: 'assets/event/Birthday/bb2.jpg',
      name: 'Candy-themed Birthday Decor',
      price: '2000',
      text: 'A stunning pink and gold-themed balloon arrangement with floral accents and foil details....',
      inclusions: [
        { include: 'Candy-themed decorations, including large lollipops and candy canes, enhancing the festive atmosphere.' },
        { include: 'Balloon Arch of 150 balloons in Pink, White, and Gold colors, arranged around the entryway.' },
        { include: 'Two Gold number balloons placed at the center to display the age.' },
        { include: 'Illuminated "Happy Birthday" sign placed in the center.' },
        { include: 'Two large Star foil balloons added for additional flair.' },
        { include: 'Various pastel-colored balloon clusters scattered around the venue.' },
      ],
      aboutExperience: 'Indulge in the enchantment of the cutest baby shower decorations you have ever seen! Get ready to be swept away by our dreamy and captivating sky baby shower decoration theme. We have carefully curated every element to ensure a truly magical experience for you and your loved ones. Picture yourself amidst this breathtaking decoration, surrounded by the warmth of family and friends. Its an incredible sight that will fill your heart with joy and excitement.',
    },
    {
      id: 3,
      image: 'assets/event/Birthday/bb14.jpg',
      name: 'Butterfly theme Birthday Decor',
      price: '2000',
      text: 'The party decor seems to be centered around a "Butterfly" theme, with pastel colors and butterfly motifs dominating the design.',
      inclusions: [
        { include: 'A large, three-panel backdrop featuring large butterfly cutouts in various pastel shades.' },
        { include: 'A balloon arch made of pastel-colored balloons, including pink, peach, and blue.' },
        { include: 'Smaller clusters of balloons are placed around the setup, including some with metallic accents.' },
        { include: 'Three cylindrical stands decorated with flowers and small butterflies.' },
        { include: 'Candles and lanterns for ambiance.' },
        { include: 'Small butterfly-shaped decorations scattered around the room.' },
      ],
      aboutExperience: 'The decor creates a delicate and feminine atmosphere, perfect for a young child’s birthday celebration. The butterfly theme is effectively conveyed through the use of colors, shapes, and motifs.',
    },
    {
      id: 4,
      image: 'assets/event/Birthday/bb4.jpg',
      name: 'Rainbow theme Birthday Decor',
      price: '2000',
      text: 'An elegant rainbow arch design with white stars and golden accents for a vibrant celebration...',
      inclusions: [
        { include: 'Balloon Arch of 120 balloons in Rainbow colors, arranged in a curve shape.' },
        { include: 'Gold stars attached to the balloon arch for a decorative effect.' },
        { include: 'Neon "Happy Birthday" sign.' },
        { include: 'Gold number "5" balloon placed on...' },
        // Add more inclusions as needed
      ],
      aboutExperience: 'Experience the vibrant colors and joyous atmosphere that our rainbow theme brings to your special day!',
    },
    {
      id: 4,
      image: 'assets/event/Birthday/bb4.jpg',
      name: 'Rainbow theme Birthday Decor',
      price: '2000',
      text: 'An elegant rainbow arch design with white stars and golden accents for a vibrant celebration...',
      inclusions: [
        { include: 'Balloon Arch of 120 balloons in Rainbow colors, arranged in a curve shape.' },
        { include: 'Gold stars attached to the balloon arch for a decorative effect.' },
        { include: 'Neon "Happy Birthday" sign.' },
        { include: 'Gold number "5" balloon placed on...' },
        // Add more inclusions as needed
      ],
      aboutExperience: 'Experience the vibrant colors and joyous atmosphere that our rainbow theme brings to your special day!',
    },
    {
      id: 4,
      image: 'assets/event/Birthday/bb4.jpg',
      name: 'Rainbow theme Birthday Decor',
      price: '2000',
      text: 'An elegant rainbow arch design with white stars and golden accents for a vibrant celebration...',
      inclusions: [
        { include: 'Balloon Arch of 120 balloons in Rainbow colors, arranged in a curve shape.' },
        { include: 'Gold stars attached to the balloon arch for a decorative effect.' },
        { include: 'Neon "Happy Birthday" sign.' },
        { include: 'Gold number "5" balloon placed on...' },
        // Add more inclusions as needed
      ],
      aboutExperience: 'Experience the vibrant colors and joyous atmosphere that our rainbow theme brings to your special day!',
    },
    // Add more birthday packages as needed
  ];

  const renderPackages = (packages) => {
    return packages.map((pkg) => (
      <div key={pkg.id} className="flex flex-col gap-3 pb-3">
        <div
          className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl"
          style={{ backgroundImage: `url(${pkg.image})` }}
        ></div>
        <div>
          <p className="text-[#111318] text-base font-medium leading-normal">{pkg.name}</p>
          <p className="text-[#111318] text-lg font-bold">{pkg.price}</p>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="" />
        <link
          rel="stylesheet"
          as="style"
          onLoad="this.rel='stylesheet'"
          href="https://fonts.googleapis.com/css2?display=swap&amp;family=Noto+Sans%3Awght%40400%3B500%3B700%3B900&amp;family=Plus+Jakarta+Sans%3Awght%40400%3B500%3B700%3B800"
        />
        <title>Galileo Design</title>
        <link rel="icon" type="image/x-icon" href="data:image/x-icon;base64," />
        <script src="https://cdn.tailwindcss.com?plugins=forms,container-queries"></script>
      </Helmet>
      <div className="px-40 flex flex-1 justify-center py-5">
        <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
          <h3 className="text-[#111318] text-lg font-bold leading-tight tracking-[-0.015em] px-4 pb-2 pt-4">Birthday Packages</h3>
          <div className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
            {renderPackages(birthdayPackages)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPage;
