export const birthdaypackages = [
    {
      id: 1,
      image: 'assets/event/Birthday/bb1.jpg',
      name: 'Aviation theme Birthday Decor',
      text: 'Featuring a balloon arch,airplane,neon signs, and vibrant pastel balloons for a whimsical celebration Decoration Theme',
      price:'2000',
      inclusions: [
        {
            "include": "Balloon Arch of 150 balloons in Red, Blue, White, and Gray colors, arranged in clusters to create a cloud-like effect."
          },
          {
            "include": "Paper Clouds attached to the backdrop and surrounding the arch."
          },
          {
            "include": "Bubble Balloons placed within the arch."
          },
          {
            "include": "Airplane toy prop suspended amidst the balloon clusters."
          },
          {
            "include": "Cloud-shaped Sunboard cutouts (6x4ft) as backdrop panels."
          },
          {
            "include": "Neon 'Happy Birthday' sign and illuminated number '5'."
          },
          {
            "include": "4 18-inch Big pastel balloons in various colors."
          },
          {
            "include": "Transparent cubes with 'BAB' letters filled with balloons."
          }
      ],
      aboutExperince: 'Indulge in the enchantment of the cutest baby shower decorations you have ever seen! Get ready to be swept away by our dreamy and captivating sky baby shower decoration theme. We have carefully curated every element to ensure a truly magical experience for you and your loved ones. Picture yourself amidst this breathtaking decoration, surrounded by the warmth of family and friends. Its an incredible sight that will fill your heart with joy and excitement.',
    },
    {
      id: 2,
      image: 'assets/event/Birthday/bb2.jpg',
      name: 'Candy-themed Birthday Decor ',
      price:'2000',
      text: 'A stunning pink and gold-themed balloon arrangement with floral accents and foil details....',
      inclusions: [
        {
            "include": "Candy-themed decorations, including large lollipops and candy canes, enhancing the festive atmosphere."
        },
        {
          "include": "Balloon Arch of 150 balloons in Pink, White, and Gold colors, arranged around the entryway."
        },
        {
          "include": "Two Gold number balloons placed at the center to display the age."
        },
        {
          "include": "Illuminated 'Happy Birthday' sign placed in the center."
        },
        {
          "include": "Two large Star foil balloons added for additional flair."
        },
        {
          "include": "Various pastel-colored balloon clusters scattered around the venue."
        },
       
      ],
      aboutExperince: 'Indulge in the enchantment of the cutest baby shower decorations you have ever seen! Get ready to be swept away by our dreamy and captivating sky baby shower decoration theme. We have carefully curated every element to ensure a truly magical experience for you and your loved ones. Picture yourself amidst this breathtaking decoration, surrounded by the warmth of family and friends. Its an incredible sight that will fill your heart with joy and excitement.',
    
    },
    {
      id: 3,
      image: 'assets/event/Birthday/bb14.jpg',
      name: 'Butterfly theme Birthday Decor',
      price:'2000',
      text: ' The party decor seems to be centered around a "Butterfly" theme, with pastel colors and butterfly motifs dominating the design.',
      inclusions: [
        {
          "include": "A large, three-panel backdrop featuring large butterfly cutouts in various pastel shades"
        },
        {
          "include": "A balloon arch made of pastel-colored balloons, including pink, peach, and blue"
        },
        {
          "include": "Smaller clusters of balloons are placed around the setup, including some with metallic accents"
        },
        {
          "include": "Three cylindrical stands decorated with flowers and small butterflies."
        },
        {
          "include": "Candles and lanterns for ambiance."
        },
        {
          "include": "Small butterfly-shaped decorations scattered around the room."
        }
      ],
      aboutExperince: 'The decor creates a delicate and feminine atmosphere, perfect for a young childs birthday celebration. The butterfly theme is effectively conveyed through the use of colors, shapes, and motifs.',
    },
    {
      id: 4,
      image: 'assets/event/Birthday/bb4.jpg',
      name: 'Rainbow theme Birthday Decor',
      price:'2000',
      text: 'An elegant rainbow arch design with white stars and golden accents for a vibrant celebration...',
      inclusions: [
        {
          "include": "Balloon Arch of 120 balloons in Rainbow colors, arranged in a curve shape."
        },
        {
          "include": "Gold stars attached to the balloon arch for a decorative effect."
        },
        {
          "include": "Neon 'Happy Birthday' sign."
        },
        {
          "include": "Gold number '5' balloon placed on the arch."
        },
        {
          "include": "White and gold-themed small tables for cake placement."
        },
        {
          "include": "Additional scattered balloons and colorful floral balloon clusters around the setup."
        }
      ],
      aboutExperince: 'Indulge in the enchantment of the cutest baby shower decorations you have ever seen! Get ready to be swept away by our dreamy and captivating sky baby shower decoration theme. We have carefully curated every element to ensure a truly magical experience for you and your loved ones. Picture yourself amidst this breathtaking decoration, surrounded by the warmth of family and friends. Its an incredible sight that will fill your heart with joy and excitement.',
    
    },
    {
      id: 5,
      image: 'assets/event/Birthday/bb19.jpg',
      name: 'Ocean theme Birthday Decor',
      text: 'A colorful underwater-themed birthday party with balloons, decorations, and a number for  birthday celebration.',
      price:'2000',
      inclusions: [
        {
          include: ' A colorful scene featuring cartoon-style sea creatures like starfish, octopus, and fish'
        },
        {
          include: 'A white structure resembling coral or sea foam, designed for photo opportunities.'
        },
        {
          include: 'A themed table with alphabet blocks spelling out “ONE,” numbers “1 2 3,” a treasure chest, and an anchor.'
        },
        {
          include: 'Decorative items like treasure chests and sea plants.'
        }
      ],
      aboutExperince: ' The air is filled with excitement as kids explore the ocean floor, surrounded by friendly sea creatures. Balloons sway gently, mimicking the ebb and flow of waves. Laughter echoes as little ones discover hidden treasures in the treasure chests',
    
    },
    {
      id: 6,
      image: 'assets/event/Birthday/bb24.jpg',
      name: 'Shimmer and Sparkle Birthday Decor',
      price: '3200',
      text: 'The party decor seems to be centered around a blue and gold color scheme, with a touch of sparkle.',
      inclusions: [
        {
          include: 'A large, arched arrangement of balloons in blue, silver, and gold colors.'
        },
        {
            include: 'Have a large silver sparkling screen '
        },
        {
          include: 'Two wooden barrels or stands placed in the backdrop.'
        },
        {
          include: 'Small decorative items and flowers arranged on top of the barrels'
        },
        {
          include: 'Several small, decorative boxes and baskets scattered around the base of the arch.'
        }
      ],
      aboutExperince: 'The decor creates a glamorous and festive atmosphere, with the blue and gold color scheme and the shimmering backdrop adding a touch of elegance. The balloon arch and the wooden barrels provide a visually appealing focal point for the celebration',
    
    },
    {
      id: 7,
      image: 'assets/event/Birthday/bb7.jpg',
      name: 'Pink Butterfly theme Birthday Decor',
      price: '3200',
      text: 'The combination of delicate pastel colors, intricate balloon arrangements, and elegant lighting creates a magical and enchanting atmosphere for the birthday celebration.',
      inclusions: [
        {
          include: 'A large, three-panel backdrop featuring large butterfly cutouts in purple and white.'
        },
        {
          include: 'A balloon arch made of pastel-colored balloons, including pink, purple, and white.'
        },
        {
          include: 'Small butterfly-shaped decorations scattered around the room, a lit-up "Happy Birthday" sign'
        },
        {
          include: 'The predominant colors in the decor are pastel shades of pink, purple, and white, creating a soft and feminine ambiance.'
        }
      ],
      aboutExperince: 'Beautifully decorated birthday party with a butterfly theme. The pastel colors, butterfly motifs, and elegant lighting create a festive and enchanting atmosphere.',
    },
    {
        id: 8,
        image: 'assets/event/Birthday/bb9.jpg',
        name: 'Gold Butterfly theme Birthday Decor',
        price: '3200',
        text: 'The combination of delicate pastel colors, intricate balloon arrangements, and elegant lighting creates a magical and enchanting atmosphere for the birthday celebration.',
        inclusions: [
          {
            include: 'A shimmering gold sequined wall with butterfly cutouts.'
          },
          {
            include: 'A balloon arch made of pastel-colored balloons, including pink, purple, and gold.'
          },
          {
            include: 'A lit-up "Happy Birthday" sign and a giant "respctive" name sign'
          },
          {
            include: 'Butterfly-shaped decorations scattered around the venue.'
          }
        ],
        aboutExperince: 'Beautifully decorated birthday party with a butterfly theme. A large, arched arrangement of balloons in shades of pink, purple, and gold frames the backdrop, which features a shimmering silver sequined wall with butterfly cutouts. A lit-up "Happy Birthday" sign and a giant "TRISHIKA" name sign add to the festive atmosphere. The centerpiece is a decorated cake stand surrounded by small flower arrangements.',
      },
      {
        id: 9,
        image: 'assets/event/Birthday/bb13.jpg',
        name: ' Jungle Adventure theme Birthday Decor',
        price: '3200',
        text: 'Beautifully decorated birthday party with a jungle adventure theme. The vibrant colors, animal cutouts, and jeep centerpiece create a fun and engaging atmosphere for children',
        inclusions: [
          {
            include: 'A green backdrop with safari-themed cutouts, including trees, animals, and a jeep.'
          },
          {
            include: 'Balloon Arch: A colorful balloon arch with balloons in green, yellow, and blue.'
          },
          {
            include: 'Centerpiece: A jeep cutout placed in the center of the backdrop.'
          },
          {
            include: 'Animal Cutouts: Large cutouts of various jungle animals, such as lions, zebras, tigers, and deer, are placed around the setup.'
          },
          {
            include: 'Decorations: Small decorative items, including lanterns, birdcages, and plant pots, are scattered around the stage.'
          },
          {
            include: 'Additional Elements: A small train and elephant toy are placed on the floor.'
          },
        ],
        aboutExperince: 'Beautifully decorated birthday party with a jungle adventure theme. The vibrant colors, animal cutouts, and jeep centerpiece create a fun and engaging atmosphere for children',
      },
      {
        id: 10,
        image: 'assets/event/Birthday/bb16.jpg',
        name: 'Boss Baby Birthday Decor',
        price: '3200',
        text: 'Birthday party decoration with a Boss Baby theme.surrounded by balloons in blue, white, and silver. There are also various Boss Baby-themed cutouts and decorations, including a large Boss Baby figure, a briefcase, and a tie. The overall atmosphere is festive and colorful, perfect for a childs birthday celebration.',
        inclusions: [
          {
            include: 'Backdrop: A backdrop featuring a large "Boss Baby" cutout and other themed graphics.'
          },
          {
            include: 'Balloon Arch: A balloon arch with blue, white, and silver balloons, arranged in a curved shape.'
          },
          {
            include: 'Centerpiece: A large, lit-up "ADHRITH" name sign placed in the center.'
          },
          {
            include: 'Decorations: Several Boss Baby-themed cutouts, including a large Boss Baby figure, a briefcase, and a tie.'
          },
          {
            include: 'Additional Elements: A small cake stand with a decorated cake and a few small balloons.'
          },
        ],
        aboutExperince: 'Birthday party decoration with a Boss Baby theme.surrounded by balloons in blue, white, and silver. There are also various Boss Baby-themed cutouts and decorations, including a large Boss Baby figure, a briefcase, and a tie. The overall atmosphere is festive and colorful, perfect for a childs birthday celebration.',
      },
      {
        id: 11,
        image: 'assets/event/Birthday/bb23.jpg',
        name: 'Hot Air Balloon AdventureBirthday Decor',
        price: '3200',
        text: 'Birthday party decoration with a hot air balloon theme. The centerpiece is a colorful balloon arch, surrounded by hot air balloon-themed decorations and a lit-up name sign. The overall atmosphere is festive and whimsical, perfect for a childs birthday celebration...',
        inclusions: [
          {
            include: 'Backdrop: A blue backdrop with clouds and hot air balloons printed on it.'
          },
          {
            include: 'Balloon Arch: A colorful balloon arch with balloons in blue, silver, and green.'
          },
          {
            include: 'Centerpiece: Three cylindrical stands decorated with hot air balloon-themed paper lanterns.'
          },
          {
            include: 'Name Sign: A lit-up "ARYAN NIHAR" name sign placed at the front of the setup.'
          },
          {
            include: 'Additional Elements: A small Ferris wheel decoration and a few small gift boxes.'
          },
        ],
        aboutExperince: 'Birthday party decoration with a hot air balloon theme. The centerpiece is a colorful balloon arch, surrounded by hot air balloon-themed decorations and a lit-up name sign. The overall atmosphere is festive and whimsical, perfect for a childs birthday celebration.',
      }
  ];