import React, { Fragment, useState } from "react";
import Modal from "react-modal";
import Navigation from "./navigation";
import Footer from "./footer";
import { Link } from "react-router-dom";
import "../css/packages.css";
import { funCategories } from "../data/funactivites";
import facepainting from "../images/facepainting.jpg";
import traditionlphotographer from "../images/trditionalphotographer.jpg";
import tatooartist from "../images/tatooartist.jpg";
import funact from "../images/funact.jpg";

function FunActivities() {
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("All");
  const [typeFilter, setTypeFilter] = useState("All");
  const [selectedTag, setSelectedTag] = useState(""); // State for selected tag
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedActivity, setExpandedActivity] = useState(null);

  // Function to toggle "Read More" details
  const toggleReadMore = (activity) => {
    if (expandedActivity === activity) {
      setExpandedActivity(null);
    } else {
      setExpandedActivity(activity);
    }
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
};
  const openModal = (activity) => {
    setSelectedActivity(activity);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedActivity(null);
    setIsModalOpen(false);
  };

  // Function to handle tag click with toggle functionality
  const handleTagClick = (tag) => {
    if (selectedTag === tag) {
      // If the tag is already selected, clear the filter
      setSelectedTag("");
      setCategoryFilter("All");
    } else {
      // Otherwise, set the selected tag and apply the filter
      setSelectedTag(tag);
      if (tag.includes("Photography")) {
        setCategoryFilter("Photography");
      } else if (tag.includes("Art")) {
        setCategoryFilter("Art");
      } else if (tag.includes("Entertainment")) {
        setCategoryFilter("Entertainment");
      } else if (tag.includes("Beauty")) {
        setCategoryFilter("Beauty");
      } else {
        setCategoryFilter("All");
      }
    }
  };

  const filteredActivities = funCategories.filter((activity) => {
    const matchesSearch = activity.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesCategory =
      categoryFilter === "All" || activity.category === categoryFilter;
    const matchesType = typeFilter === "All" || activity.type === typeFilter;
    return matchesSearch && matchesCategory && matchesType;
  });
  const galleryImages = [
    { src: facepainting, alt: "Face Painting" },
    { src: traditionlphotographer, alt: "Traditional Photography" },
    { src: tatooartist, alt: "Tattoo Artist" },
  ];

  return (
    <Fragment>
      <div>
        <header className="bg-black text-white py-4">
          <Navigation />
        </header>
        <main>
          {/* Floating contact buttons */}
          <a
            href="https://wa.me/+917619233640"
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-whatsapp whatsapp-icon"></i>
          </a>

          <a
            href="tel:+917619233640"
            className="phone_float"
            rel="noopener noreferrer"
          >
            <i className="fa fa-phone phone-icon"></i>
          </a>
          <div
            className="bg-gray-100 w-full flex flex-col md:flex-row items-center md:items-center justify-center px-6"
            style={{ marginTop: "50px", padding: "30px" }}
          >
            {/* Content Section */}
            <div className="flex-1 md:mr-8 text-center md:text-left">
              <h2 className="text-3xl font-bold text-red-500 mb-4">
                Welcome to Our Fun Activities
              </h2>
              <p className="text-md text-gray-700 max-w-lg mx-auto md:mx-0">
                Discover an array of exciting activities designed to bring joy
                and entertainment to your events. From traditional photography
                to interactive art experiences, we have something for everyone
                to enjoy.
              </p>
            </div>

            {/* Image Section */}
            <img
              src={funact} // Make sure to update with the correct relative path to your image
              alt="Fun Activities"
              className="w-full max-w-md mt-6 md:mt-0 rounded-lg shadow-md"
            />
          </div>

          <div className="bg-white py-10 px-4 w-full flex flex-col items-center">
            <h2 className="text-3xl font-bold text-black mb-4">
              Activity Highlights
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl">
              <div className="bg-gray-100 p-6 rounded-lg shadow-md text-center">
                <i className="fas fa-paint-brush text-4xl text-red-500 mb-3"></i>
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  Creative Art
                </h3>
                <p className="text-gray-700">
                  Unleash your creativity with our face painting and caricature
                  artists.
                </p>
              </div>
              <div className="bg-gray-100 p-6 rounded-lg shadow-md text-center">
                <i className="fas fa-camera text-4xl text-blue-500 mb-3"></i>
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  Memorable Moments
                </h3>
                <p className="text-gray-700">
                  Capture every precious moment with our professional
                  photography services.
                </p>
              </div>
              <div className="bg-gray-100 p-6 rounded-lg shadow-md text-center">
                <i className="fas fa-magic text-4xl text-purple-500 mb-3"></i>
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  Magical Entertainment
                </h3>
                <p className="text-gray-700">
                  Keep guests entertained with our balloon modeling and magic
                  acts.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-gray-100 py-8 px-4 w-full flex flex-col items-center">
            <h2 className="text-3xl font-bold text-black mb-6">Discover</h2>

            {/* Tags Row for Activities */}
            <div className="flex flex-wrap justify-center gap-3">
              {[
                "TraditionalPhotography",
                "CandidPhotography",
                "TattooArt",
                "BalloonModelling",
                "Caricature",
                "FacePainting",
                "NailArt",
              ].map((tag) => (
                <span
                  key={tag}
                  onClick={() => handleTagClick(tag)}
                  className={`px-3 py-1 cursor-pointer flex items-center gap-2 border border-gray-200 rounded-full shadow-sm ${
                    selectedTag === tag
                      ? "bg-red-500 text-white hover:bg-red-600"
                      : "bg-white text-gray-700 hover:bg-gray-200"
                  }`}
                >
                  <i className="fas fa-tag"></i> {/* Tag icon */}
                  <span>{tag}</span>
                </span>
              ))}
            </div>
          </div>

          <div className="min-h-screen bg-[#f4f1fa] flex flex-col items-center py-10 px-4 sm:px-10">
            {/* Header Section */}
            <header className="w-full max-w-6xl mb-8">
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
                <h1 className="text-3xl font-bold text-[#4c306d]">
                  Fun Activities
                </h1>
                <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-4 w-full max-w-2xl">
                  {/* Search Bar */}
                  <input
                    type="text"
                    placeholder="Search activities"
                    className="w-full px-4 py-2 border border-[#d6b3e1] rounded-lg focus:outline-none focus:border-[#a76ebe] bg-white text-gray-700 shadow-sm"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <button className="bg-[#a76ebe] text-white px-4 py-2 rounded-lg shadow-md hover:bg-[#8b5c9e]">
                    Search
                  </button>
                </div>
              </div>
            </header>

            {/* Featured Activities */}
            <div className="w-full max-w-6xl mb-10">
              <h3 className="text-2xl font-bold text-[#4c306d] mb-6">
                Featured Activities
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {filteredActivities.length > 0 ? (
                  filteredActivities.map((activity, index) => (
                    <div
                      key={index}
                      className="bg-white rounded-lg shadow-md p-6 flex flex-col md:flex-row items-start gap-6"
                    >
                      <img
                        src={activity.image}
                        alt={activity.name}
                        className="w-full md:w-48 h-48 object-cover rounded-lg"
                      />
                      <div className="flex-1">
                        <h4 className="text-lg font-semibold text-[#4c306d]">
                          {activity.name}
                        </h4>
                        <p className="text-gray-700 mt-1">
                          {activity.description}
                        </p>
                        <p className="text-md font-bold text-gray-800 mt-2">
                          {activity.priceString}
                        </p>
                        <p className="text-sm text-gray-500">
                          Includes: {activity.includes}
                        </p>
                        <button
                          className="mt-2 text-[#a76ebe] font-semibold hover:text-[#8b5c9e]"
                          onClick={() => toggleReadMore(activity)}
                        >
                          {expandedActivity === activity
                            ? "Show less"
                            : "Read more"}
                        </button>

                        {/* Expanded Details */}
                        {expandedActivity === activity && (
                          <div className="mt-3 text-gray-600">
                            <p>
                              <strong>Details:</strong> {activity.details}
                            </p>
                            <p>
                              <strong>Reviews:</strong>
                            </p>
                            <ul className="list-disc list-inside">
                              {activity.reviews.map((review, i) => (
                                <li key={i}>
                                  {review.comment} - <em>{review.reviewer}</em>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-center text-lg text-gray-500">
                    No activities found.
                  </p>
                )}
              </div>
            </div>

            {/* Contact Us Section */}
            <div className="mt-12">
              <Link
              onClick={scrollToTop}
                to="/contact"
                className="bg-[#ff6f91] text-white py-3 px-8 rounded-full font-semibold shadow-md hover:bg-[#e65b7a] transition-transform transform hover:scale-105"
              >
                Contact Us
              </Link>
            </div>
          </div>

          <div className="bg-yellow-100 py-10 px-4 w-full flex flex-col items-center">
            <h2 className="text-3xl font-bold text-yellow-600 mb-4">
              Special Offers
            </h2>
            <p className="text-md text-gray-700 max-w-3xl text-center">
              Book multiple activities and get exclusive discounts! Take
              advantage of our seasonal offers and make your event unforgettable
              without breaking the bank. Contact us today to learn more about
              our deals!
            </p>
            <Link
              to="/contact"
              className="mt-4 bg-yellow-500 text-white py-3 px-8 rounded-full font-semibold shadow-md hover:bg-yellow-600 transition-transform transform hover:scale-105"
            >
              Learn More
            </Link>
          </div>

          <div className="relative bg-gradient-to-r from-pink-200 via-purple-300 to-blue-200 py-8 w-full flex flex-col items-center overflow-hidden">
            {/* Confetti Elements for Celebration Effect */}
            <div className="absolute top-0 left-0 w-1/2 h-full overflow-hidden">
              <div className="absolute top-10 left-5 w-4 h-4 bg-pink-400 rounded-full animate-bounce"></div>
              <div className="absolute top-20 left-16 w-3 h-3 bg-yellow-400 rounded-full animate-bounce"></div>
              <div className="absolute top-32 left-10 w-5 h-5 bg-blue-400 rounded-full animate-bounce"></div>
              <div className="absolute top-40 left-20 w-3 h-3 bg-green-400 rounded-full animate-bounce"></div>
            </div>
            <div className="absolute top-0 right-0 w-1/2 h-full overflow-hidden">
              <div className="absolute top-10 right-5 w-4 h-4 bg-purple-400 rounded-full animate-bounce"></div>
              <div className="absolute top-24 right-16 w-3 h-3 bg-orange-400 rounded-full animate-bounce"></div>
              <div className="absolute top-36 right-10 w-5 h-5 bg-red-400 rounded-full animate-bounce"></div>
              <div className="absolute top-50 right-20 w-3 h-3 bg-teal-400 rounded-full animate-bounce"></div>
            </div>

            {/* Header */}
            <h2 className="text-3xl font-bold text-[#4c306d] mb-6 text-center">
              Featured Activities
            </h2>

            {/* Activities */}
            <div className="flex flex-wrap justify-center gap-8">
              <div className="flex flex-col items-center">
                <img
                  src={tatooartist}
                  alt="Tattoo Artist"
                  className="mb-2 rounded-full border-4 border-pink-400 shadow-lg transform hover:scale-110 transition duration-300"
                  style={{ width: "150px", height: "150px" }}
                />
                <p className="text-lg font-semibold text-pink-600 text-center">
                  Tattoo Artist
                </p>
              </div>
              <div className="flex flex-col items-center">
                <img
                  src={facepainting}
                  alt="Face Painting"
                  className="mb-2 rounded-full border-4 border-yellow-400 shadow-lg transform hover:scale-110 transition duration-300"
                  style={{ width: "150px", height: "150px" }}
                />
                <p className="text-lg font-semibold text-yellow-600 text-center">
                  Face Painting
                </p>
              </div>
              <div className="flex flex-col items-center">
                <img
                  src={traditionlphotographer}
                  alt="Traditional Photography"
                  className="mb-2 rounded-full border-4 border-blue-400 shadow-lg transform hover:scale-110 transition duration-300"
                  style={{ width: "150px", height: "150px" }}
                />
                <p className="text-lg font-semibold text-blue-600 text-center">
                  Traditional Photography
                </p>
              </div>
            </div>
          </div>

          <div className="bg-green-100 py-10 px-4 w-full flex flex-col items-center">
            <h2 className="text-3xl font-bold text-green-800 mb-4">
              Seasonal Specials
            </h2>
            <p className="text-md text-gray-700 max-w-3xl text-center mb-6">
              Celebrate the holidays with our themed activities and packages.
              From Halloween to Christmas, we've got you covered!
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl">
              <div className="bg-white p-6 rounded-lg shadow-md text-center">
                <h3 className="text-lg font-semibold text-gray-800">
                  Spooky Halloween Bash
                </h3>
                <p className="text-gray-700">
                  Pumpkin face painting, ghostly balloon art, and a photo booth
                  with scary props!
                </p>
                <button className="mt-4 bg-green-500 text-white py-2 px-4 rounded-full hover:bg-green-600">
                  Learn More
                </button>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md text-center">
                <h3 className="text-lg font-semibold text-gray-800">
                  Magical Christmas Party
                </h3>
                <p className="text-gray-700">
                  Santa photo ops, holiday-themed caricatures, and festive
                  balloon sculptures!
                </p>
                <button className="mt-4 bg-green-500 text-white py-2 px-4 rounded-full hover:bg-green-600">
                  Book Now
                </button>
              </div>
            </div>
          </div>

          <div className="bg-white py-10 px-4 w-full flex flex-col items-center">
            <h2 className="text-3xl font-bold text-black mb-4">
              About Our Activities
            </h2>
            <p className="text-md text-gray-700 max-w-3xl text-center">
              Our fun activities are curated to add excitement to your events,
              whether it's a birthday party, a corporate event, or a casual
              get-together. From face painting that brings smiles to everyone's
              faces to professional photography that captures your special
              moments, we have something for everyone. Each activity is led by
              experienced and passionate artists dedicated to creating memorable
              experiences.
            </p>
          </div>

          <div className="bg-gray-50 py-10 px-4 w-full flex flex-col items-center">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">
              Success Stories
            </h2>
            <div className="max-w-4xl w-full grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  Amazing Birthday Bash
                </h3>
                <p className="text-gray-700">
                  "Our daughter’s 5th birthday was unforgettable thanks to the
                  face painting and balloon modeling. The kids were entertained
                  for hours!" - Jessica T.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  Corporate Event Fun
                </h3>
                <p className="text-gray-700">
                  "We hired the team for our annual company picnic, and the
                  caricature artist was a huge hit. Everyone had a great time!"
                  - Michael S.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-purple-100 py-10 px-4 w-full flex flex-col items-center">
            <h2 className="text-3xl font-bold text-purple-800 mb-4">
              Did You Know?
            </h2>
            <div className="max-w-3xl text-center space-y-4">
              <p className="text-md text-gray-700">
                🎨 The art of face painting dates back to ancient times and was
                used for cultural rituals and ceremonies.
              </p>
              <p className="text-md text-gray-700">
                📸 The first known photograph was taken in 1826 by Joseph
                Nicéphore Niépce, and it took 8 hours to develop!
              </p>
              <p className="text-md text-gray-700">
                🎈 Balloon modeling is believed to have been invented by a
                magician named Herman Bonnert in the 1930s.
              </p>
            </div>
          </div>

          <div className="bg-blue-100 py-10 px-4 w-full flex flex-col items-center">
            <h2 className="text-3xl font-bold text-blue-800 mb-4">
              Activity Planner
            </h2>
            <p className="text-md text-gray-700 max-w-3xl text-center mb-6">
              Not sure which activities to choose? Use our Activity Planner to
              create the perfect experience for your event.
            </p>
            <Link
              to="/activity-planner"
              className="bg-blue-500 text-white py-3 px-8 rounded-full font-semibold shadow-md hover:bg-blue-600 transition-transform transform hover:scale-105"
            >
              Plan Your Event
            </Link>
          </div>
          <div className="bg-pink-100 py-10 px-4 w-full flex flex-col items-center">
            <h2 className="text-3xl font-bold text-pink-800 mb-4">
              DIY Fun Activities
            </h2>
            <p className="text-md text-gray-700 max-w-3xl text-center mb-6">
              Looking for fun activities to do at home? Try our easy DIY
              tutorials and bring the joy of our events to your living room!
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-lg font-semibold text-gray-800">
                  Make Your Own Balloon Animals
                </h3>
                <p className="text-gray-700 mb-4">
                  Follow our step-by-step guide to twist balloons into fun
                  shapes and characters.
                </p>
                <button className="bg-pink-500 text-white py-2 px-4 rounded-full hover:bg-pink-600">
                  View Tutorial
                </button>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-lg font-semibold text-gray-800">
                  Face Painting for Beginners
                </h3>
                <p className="text-gray-700 mb-4">
                  Learn simple face painting designs and techniques to make your
                  kids smile.
                </p>
                <button className="bg-pink-500 text-white py-2 px-4 rounded-full hover:bg-pink-600">
                  Watch Video
                </button>
              </div>
            </div>
          </div>
        </main>
        <footer className="bg-black text-white py-4">
          <Footer />
        </footer>
        <div id="back-top">
          <a
            href="#"
            className="text-white bg-red-500 p-3 rounded-full shadow-lg hover:bg-red-600 transition-colors"
            title="Go to Top"
          >
            <i className="fas fa-level-up-alt"></i>
          </a>
        </div>

        {/* Modal for Activity Details */}
        {selectedActivity && (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Activity Details"
            className="modal"
            overlayClassName="modal-overlay"
          >
            <div className="p-4 bg-white rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold text-red-500 mb-4">
                {selectedActivity.name}
              </h2>
              <p className="mb-2 text-gray-700">
                {selectedActivity.description}
              </p>
              <p className="font-medium mb-2 text-black">
                Price: {selectedActivity.priceString}
              </p>
              <p className="mb-4 text-gray-700">
                Includes: {selectedActivity.includes}
              </p>
              <button
                onClick={closeModal}
                className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600"
              >
                Close
              </button>
            </div>
          </Modal>
        )}
      </div>
      <div id="back-top">
        <a href="#" title="Go to Top">
          <i className="fas fa-level-up-alt" />
        </a>
      </div>
    </Fragment>
  );
}

export default FunActivities;
