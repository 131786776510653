import React, { Fragment,useState } from "react";
import "../css/about.css";
import Navigation from "./navigation";
import Footer from "./footer";

function About() {
  const [formData, setFormData] = useState({
    message: '',
    name: '',
    email: '',
    selectedPackage: '', 
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSmsClick = () => {
    const phoneNumber = "+919019261365"; // Replace with the desired phone number
    const smsMessage = `Hello, I'm ${formData.name}. I'm interested in the ${formData.selectedPackage} package and would love to learn more about it. Could you please provide me with additional details and any special offers that might be available? I appreciate your assistance and look forward to hearing from you soon. Thank you!`;
    const smsLink = `sms:${phoneNumber}?body=${encodeURIComponent(smsMessage)}`;
    window.location.href = smsLink;
  };
  return (
    <Fragment>
      <header style={{ height: "93px" }}>
        <Navigation />
      </header>
      <div>
        <main>
          <a
            href="https://wa.me/+917338011713"
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa fa-whatsapp whatsapp-icon"></i>
          </a>
          <a
            href="tel:${+917338011713}"
            className="phone_float"
            rel="noopener noreferrer"
          >
            <i class="fa fa-phone phone-icon"></i>
          </a>
          <div></div>
          <div className="slider-area3">
            <div className="slider-height2 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="hero-cap text-center">
                      <h2 className="header-title-about">About Us</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="about-low-area section-padding2">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="about-caption mb-50">
                    <div className="section-tittle mb-35">
                      <h2>Citywide Balloon Bliss Events.</h2>
                    </div>
                    <h5>
                      At Citywide Balloon Bliss Events, our mission is to
                      transform ordinary spaces into extraordinary experiences
                      through innovative and stunning balloon decor. We
                      specialize in creating custom balloon designs for all
                      types of events, from intimate gatherings to grand
                      celebrations.
                    </h5>
                    <h5>
                      Serving citywide, our goal is to bring joy, color, and
                      creativity to every occasion, ensuring unforgettable
                      memories for our clients
                    </h5>
                  </div>
                  {/* <div className="row d-flex justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-10">
                      <div className="single-caption mb-20">
                        <div className="caption-icon">
                          <span className="flaticon-communications-1" />
                        </div>
                        <div className="caption">
                          <h5>Services</h5>
                          <p>Bangalore, Karnataka</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <a
                    className="btn mt-50"
                    href="tel:${+917338011713}"
                    rel="noopener noreferrer"
                  >
                    Talk to our Team
                  </a>
                  <a
                    class="btn mt-50"
                    style={{ marginLeft: "10px" }}
                    href="/packages"
                    rel="noopener noreferrer"
                  >
                    Check packges
                  </a>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="about-img ">
                    <div className="about-back-img ">
                      <img
                        style={{ width: "100%" }}
                        alt=""
                        src="assets/img/about-side1.jpeg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

         
          <section
  className="work-company section-padding30"
  style={{ background: "#e60057" }} // Dark pink background
>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-5 col-md-8">
        {/* Section Title */}
        <div className="section-tittle section-tittle2 mb-50">
          <h2>What we Offer!</h2>
          <p>
            Every event and client is one of a kind, and we tailor our services
            to match. As skilled planners, we take care of every detail from
            start to finish, turning your event into an unforgettable experience.
          </p>
        </div>
      </div>
      <div className="col-lg-7">
        <div className="logo-area">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="single-logo mb-30 text-center">
        
                <i className="fa fa-smile-o fa-2x" style={{ color: "white" }}></i>
                <p style={{ color: "white", marginTop: "10px" }}>Balloon Decoration</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="single-logo mb-30 text-center">
                <i className="fa fa-calendar fa-2x" style={{ color: "white" }}></i>
                <p style={{ color: "white", marginTop: "10px" }}>Event Planning</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="single-logo mb-30 text-center">
                <i className="fa fa-camera fa-2x" style={{ color: "white" }}></i>
                <p style={{ color: "white", marginTop: "10px" }}>Photography</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="single-logo mb-30 text-center">
                <i className="fa fa-music fa-2x" style={{ color: "white" }}></i>
                <p style={{ color: "white", marginTop: "10px" }}>Music Arrangements</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="single-logo mb-30 text-center">
                <i className="fa fa-gamepad fa-2x" style={{ color: "white" }}></i>
                <p style={{ color: "white", marginTop: "10px" }}>Fun Activities</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="single-logo mb-30 text-center">
                <i className="fa fa-magic fa-2x" style={{ color: "white" }}></i>
                <p style={{ color: "white", marginTop: "10px" }}>Magic Show</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="single-logo mb-30 text-center">
                <i className="fa fa-paint-brush fa-2x" style={{ color: "white" }}></i>
                <p style={{ color: "white", marginTop: "10px" }}>Nail Arts </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="single-logo mb-30 text-center">
                <i className="fa fa-tint fa-2x" style={{ color: "white" }}></i>
                <p style={{ color: "white", marginTop: "10px" }}>Tattoo Artist</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="single-logo mb-30 text-center">
                <i className="fa fa-cutlery fa-2x" style={{ color: "white" }}></i>
                <p style={{ color: "white", marginTop: "10px" }}>Snacks</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


          <br />
          <br />
          <section className="contact-section" style={{ padding: "0" }}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className="contact-title">Get in Touch</h2>
                </div>
                <div className="col-lg-8">
      <form className="form-contact contact_form" id="contactForm" noValidate>
        <div className="row">
        <div className="col-12">
          <div className="form-group">
            <select
              className="form-control"
              id="selectedPackage"
              name="selectedPackage"
              value={formData.selectedPackage}
              onChange={handleChange}
            >
              <option value="">Select a package</option>
              <option value="Baloon Decoration">Baloon Decoration</option>
              <option value="Birthday Party">Birthday Party</option>
              <option value="Flower Decoration">Flower Decoration</option>
              <option value="wedding and Recption">Wedding and Recption</option>
              <option value="Entertainment and Fun activities">Entertainment and Fun activities</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
          
          <div className="col-sm-6">
            <div className="form-group">
              <input
                className="form-control valid"
                id="name"
                name="name"
                placeholder="Enter your name"
                type="text"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <input
                className="form-control valid"
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
          </div>
            <div className="col-12">
            <div className="form-group">
              <textarea
                className="form-control w-100"
                cols="30"
                id="message"
                name="message"
                placeholder="Enter Message"
                rows="9"
                value={formData.message}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="form-group mt-3">
          <button
            className="button button-contactForm boxed-btn"
            type="button"
            onClick={handleSmsClick}
          >
            Send via SMS
          </button>
        </div>
      </form>
    </div>
                <div className="col-lg-3 offset-lg-1">
                  <div className="media contact-info">
                    <span className="contact-info__icon">
                      <i className="ti-home" />
                    </span>
                    <div className="media-body">
                      <h3>Bangalore, Karnataka.</h3>
                      <p>Karnataka, KA 560078</p>
                    </div>
                  </div>
                  <div className="media contact-info">
                    <span className="contact-info__icon">
                      <i className="ti-tablet" />
                    </span>
                    <div className="media-body">
                      <h3>+91 73380 11713</h3>
                      <p>Sun to Sat 9am to 6pm</p>
                    </div>
                  </div>
                  <div className="media contact-info">
                    <span className="contact-info__icon">
                      <i className="fa fa-whatsapp" />
                    </span>
                    <div className="media-body">
                      <h3>+91 73380 11713</h3>
                      <p>Text us anytime</p>
                    </div>
                  </div>
                  <div className="media contact-info">
                    <span className="contact-info__icon">
                      <i className="ti-email" />
                    </span>
                    <div className="media-body">
                      <h3>vrvvinodmani@gmail.com</h3>
                      <p>Send us your query anytime!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer>
          <Footer />
        </footer>
        <div id="back-top">
          <a href="#" title="Go to Top">
            {" "}
            <i className="fas fa-level-up-alt" />
          </a>
        </div>
      </div>
    </Fragment>
  );
}
export default About;
