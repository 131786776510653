export const babyshowerpackages = [
    {
        id: 1,
        image: 'assets/event/BabyShower/bs3.jpg', // Replace with the actual image path
        name: 'Marigold and Floral babyshower Decor',
        text: 'A vibrant and traditional babyshower decor featuring marigold garlands, floral arrangements, and a green backdrop.',
        price: '3500', // Adjust price as needed
        inclusions: [
          {
            include: 'Green backdrop with hanging marigold garlands'
          },
          {
            include: 'Floral arrangements in various colors'
          },
          {
            include: 'Additional decorative elements (e.g., lights, tassels)'
          }
        ],
        aboutExperience: 'Create a memorable babyshower experience with this stunning and colorful decor. The combination of marigolds and floral arrangements will add a touch of elegance and tradition to your special day.'
      },
      {
        id: 2,
        image: 'assets/event/BabyShower/bs4.jpg',
        name: 'Elegant babyshower Stage Decor',
        text: 'A sophisticated babyshower stage decor featuring a grand chair, floral arrangements, and fairy lights.',
        price: '4500', // Adjusted price based on additional details
        inclusions: [
          {
            include: 'Ornate gold and white upholstered chair with a carved wooden frame'
          },
          {
            include: 'Floral arrangements of white roses, lilies, and greenery on the stage and surrounding areas'
          },
          {
            include: 'String lights and fairy lights draped across the backdrop and ceiling'
          },
          {
            include: 'Wooden crates with greenery and floral arrangements as decorative elements'
          },
          {
            include: 'Custom-made backdrop with a draped fabric design'
          },
          {
            include: 'Red carpet for the stage'
          },
          {
            include: 'Sound system for announcements and music'
          },
          {
            include: 'Lighting setup for the stage and surrounding areas'
          }
        ],
        aboutExperience: 'Create a royal and elegant atmosphere with this stunning babyshower stage decor. The combination of the ornate chair, floral arrangements, and fairy lights will make your special day truly unforgettable. Enjoy the convenience of a complete setup, including sound and lighting, to ensure a seamless and memorable event.'
      },
      {
        id: 3, // Assign a unique ID
        image: 'assets/event/BabyShower/bs5.jpg', // Replace with the actual image path
        name: 'Traditional Indian babyshower Mandap Decor',
        text: 'A vibrant and traditional Indian babyshower mandap decor featuring floral garlands, a carved wooden structure, and ornate pillars.',
        price: '5000', // Adjust price as needed
        inclusions: [
          {
            include: 'Carved wooden mandap structure with intricate designs'
          },
          {
            include: 'Colorful floral garlands adorning the mandap'
          },
          {
            include: 'Ornate pillars with decorative elements'
          },
          {
            include: 'Red carpet for the mandap area'
          },
          {
            include: 'Lighting setup for the mandap and surrounding area'
          },
          {
            include: 'Sound system for rituals and music'
          }
        ],
        aboutExperience: 'Create a memorable and authentic Indian babyshower experience with this traditional mandap decor. The intricate carvings, vibrant colors, and ornate elements will add a touch of elegance and spirituality to your special day.'
      },

{
    id: 4, // Assign a unique ID
    image: 'assets/event/BabyShower/bs8.jpg', // Replace with the actual image path
    name: 'Modern babyshower Stage Decor',
    text: 'A contemporary babyshower stage decor featuring a green backdrop, floral arrangements, and gold accents.',
    price: '4200', // Adjust price as needed
    inclusions: [
      {
        include: 'Green backdrop with hanging floral arrangements'
      },
      {
        include: 'Gold curtains framing the stage'
      },
      {
        include: 'Ornate gold table and seating arrangements'
      },
      {
        include: 'Lighting setup for the stage and surrounding area'
      },
      {
        include: 'Sound system for announcements and music'
      }
    ],
    aboutExperience: 'Create a stylish and modern babyshower atmosphere with this contemporary stage decor. The combination of the green backdrop, gold accents, and elegant floral arrangements will add a touch of sophistication to your special day.'
  },
  {
    id: 5, // Assign a unique ID
    image: 'assets/event/BabyShower/bs10.jpg', // Replace with the actual image path
    name: 'Traditional Indian babyshower Stage Decor',
    text: 'A vibrant and traditional Indian babyshower stage decor featuring floral arrangements, a gold chair, and decorative elements.',
    price: '4800', // Adjust price as needed
    inclusions: [
      {
        include: 'Green backdrop with floral designs'
      },
      {
        include: 'Gold ornate chair for the bride and groom'
      },
      {
        include: 'Floral arrangements on the stage and surrounding areas'
      },
      {
        include: 'Decorative elements such as bells, baskets, and trays'
      },
      {
        include: 'Lighting setup for the stage and surrounding area'
      },
      {
        include: 'Sound system for rituals and music'
      }
    ],
    aboutExperience: 'Create a memorable and authentic Indian babyshower experience with this traditional stage decor. The vibrant colors, intricate designs, and elegant elements will add a touch of spirituality and elegance to your special day.'
  },
  {
    id: 6,
    image: 'assets/event/BabyShower/bs12.jpg', // Replace with the actual image path
    name: 'Colorful babyshower Stage Decor',
    text: 'A vibrant babyshower stage decor featuring floral arrangements, a gold chair, and decorative elements.',
    price: '4500', // Adjust price as needed
    inclusions: [
      {
        include: 'Custom-made floral backdrop with a mix of colorful flowers'
      },
      {
        include: 'Hanging floral garlands and streamers'
      },
      {
        include: 'Ornate gold chair with a carved wooden frame'
      },
      {
        include: 'Floral arrangements on the stage and surrounding areas'
      },
      {
        include: 'Decorative elements such as birds, bells, and lanterns'
      },
      {
        include: 'Lighting setup with spotlights and fairy lights'
      },
      {
        include: 'Sound system for announcements and music'
      },
      {
        include: 'Red carpet for the stage'
      },
      {
        include: 'Curtains or drapes for the backdrop'
      }
    ],
    aboutExperience: 'Create a vibrant and festive babyshower atmosphere with this colorful stage decor. The combination of floral arrangements, decorative elements, and lighting will make your special day truly memorable.'
  },
  {
    id: 7,
    image: 'assets/event/BabyShower/bs17.jpg', // Replace with the actual image path
    name: 'Elegant babyshower Stage Decor with Floral Patterns',
    text: 'A sophisticated babyshower stage decor featuring a floral backdrop, a gold chair, and decorative elements.',
    price: '4700', // Adjust price as needed
    inclusions: [
      {
        include: 'Custom-made floral backdrop with a patterned design'
      },
      {
        include: 'Hanging floral garlands and streamers'
      },
      {
        include: 'Ornate gold chair with a carved wooden frame'
      },
      {
        include: 'Floral arrangements on the stage and surrounding areas'
      },
      {
        include: 'Decorative elements such as birds, bells, and lanterns'
      },
      {
        include: 'Lighting setup with spotlights and fairy lights'
      },
      {
        include: 'Sound system for announcements and music'
      },
      {
        include: 'Red carpet for the stage'
      },
      {
        include: 'Curtains or drapes for the backdrop'
      },
      {
        include: 'Custom-made table for the bride and groom'
      },
      {
        include: 'Floral centerpieces for the table'
      }
    ],
    aboutExperience: 'Create a sophisticated and elegant babyshower atmosphere with this beautifully decorated stage. The floral patterns, ornate chair, and decorative elements will make your special day unforgettable.'
  },
  {
    id: 8,
    image: 'assets/event/BabyShower/bs18.jpg', // Replace with the actual image path
    name: 'Elegant babyshower Stage Decor with Pink and White Drapes',
    text: 'A sophisticated babyshower stage decor featuring pink and white drapes, a gold chair, and floral arrangements.',
    price: '4600', // Adjust price as needed
    inclusions: [
      {
        include: 'Pink and white fabric drapes for the backdrop'
      },
      {
        include: 'Floral arrangements at the top of the backdrop'
      },
      {
        include: 'Hanging floral garlands and streamers'
      },
      {
        include: 'Ornate gold chair with a carved wooden frame'
      },
      {
        include: 'Floral arrangements on the stage and surrounding areas'
      },
      {
        include: 'Decorative elements such as bells and lanterns'
      },
      {
        include: 'Lighting setup with spotlights and fairy lights'
      },
      {
        include: 'Sound system for announcements and music'
      },
      {
        include: 'Red carpet for the stage'
      },
      {
        include: 'Custom-made table for the bride and groom'
      },
      {
        include: 'Floral centerpieces for the table'
      },
      {
        include: 'Wooden table for refreshments'
      }
    ],
    aboutExperience: 'Create a sophisticated and elegant babyshower atmosphere with this beautifully decorated stage. The pink and white drapes, floral arrangements, and ornate chair will make your special day unforgettable.'
  }
  ];