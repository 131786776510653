import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import logo from "../images/logo3.png";

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <header
      style={{ height: "93px" }}
      className="fixed top-0 left-0 right-0 z-50 flex items-center justify-between whitespace-nowrap border-b-4 border-red-600 px-4 sm:px-10 py-3 bg-white"
    >
      <div className="flex items-center gap-4 text-gray-600 hover:text-red-600">
        <div className="w-30 h-20">
          <img
            src={logo}
            alt="Acme Construction Logo"
            className="w-full h-full object-contain"
          />
        </div>
        <h2 className="text-black text-lg font-bold leading-tight tracking-[-0.015em] hover:text-red-600">
          <Link className="text-black text-lg font-bold leading-tight tracking-[-0.015em] hover:text-red-600" onClick={scrollToTop} to="/">
            AN Events
          </Link>
        </h2>
      </div>

      {/* Hamburger Menu Icon */}
      <div className="sm:hidden flex items-center">
        <button onClick={toggleMenu} className="text-gray-600 focus:outline-none hover:text-red-600">
          <svg
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
      </div>

      {/* Navigation Links for Desktop */}
      <nav className="hidden sm:flex flex-1 justify-end gap-4 sm:gap-8">
        <div className="flex items-center gap-3 sm:gap-9">
          <Link to="/" className="text-gray-600 text-lg font-medium leading-normal hover:text-red-600" onClick={scrollToTop}>
            Home
          </Link>
          <Link to="/about" className="text-gray-600 text-lg font-medium leading-normal hover:text-red-600" onClick={scrollToTop}>
            About
          </Link>
          <Link to="/gallery" className="text-gray-600 text-lg font-medium leading-normal hover:text-red-600" onClick={scrollToTop}>
            Gallery
          </Link>
          <Link to="/packages" className="text-gray-600 text-lg font-medium py-2 hover:text-red-600" onClick={scrollToTop}>
            Packages
          </Link>
          <Link to="/funactivites" className="text-gray-600 text-lg font-medium py-2 hover:text-red-600" onClick={scrollToTop}>
              FunActivities
            </Link>
          <Link to="/contact" className="text-gray-600 text-lg font-medium leading-normal hover:text-red-600" onClick={scrollToTop}>
            Contact
          </Link>
        </div>
        <button className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-2 sm:px-4 bg-red-600 text-white text-lg font-bold leading-normal tracking-[0.015em] hover:bg-gray-800">
          <span className="truncate">
            <Link to="https://wa.me/+917338011713" onClick={scrollToTop}>
              Get a quote
            </Link>
          </span>
        </button>
      </nav>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="absolute top-full left-0 right-0 bg-white shadow-lg z-10 sm:hidden">
          <div className="flex flex-col p-4">
            <Link to="/" className="text-gray-600 text-lg font-medium py-2 hover:text-red-600" onClick={scrollToTop}>
              Home
            </Link>
            <Link to="/about" className="text-gray-600 text-lg font-medium py-2 hover:text-red-600" onClick={scrollToTop}>
              About
            </Link>
            <Link to="/gallery" className="text-gray-600 text-lg font-medium py-2 hover:text-red-600" onClick={scrollToTop}>
              Gallery
            </Link>
            <Link to="/packages" className="text-gray-600 text-lg font-medium py-2 hover:text-red-600" onClick={scrollToTop}>
              Packages
            </Link>
            <Link to="/funactivites" className="text-gray-600 text-lg font-medium py-2 hover:text-red-600" onClick={scrollToTop}>
              FunActivities
            </Link>
            <Link to="/contact" className="text-gray-600 text-lg font-medium py-2 hover:text-red-600" onClick={scrollToTop}>
              Contact
            </Link>
            <button className="flex min-w-[84px] max-w-[480px] mt-4 cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-2 sm:px-4 bg-red-600 text-white text-lg font-bold leading-normal tracking-[0.015em] hover:bg-gray-800">
              <span className="truncate">Get a quote</span>
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Navigation;
