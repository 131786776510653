import React from 'react';
import '../css/viewmore.css';

const ViewMoreCard = () => {
  return (
    <div className="view-more-card">
      <span className="arrow">→</span>
      <span className="view-more-text">View Detailed</span>
    </div>
  );
};

export default ViewMoreCard;
