import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import "../css/navigation.css"

const Footer = () => {
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

    const toggleSubmenu = () => {
        setIsSubmenuOpen(!isSubmenuOpen);
    };

    const closeSubmenu = () => {
        setIsSubmenuOpen(false);
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <div>
            <div className="footer-area footer-padding">
            <div className="container">
            <div className="row d-flex justify-content-between">
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                <div className="single-footer-caption mb-50">
                    <div className="single-footer-caption mb-30">
                    <div className="footer-tittle">
                        <h4>About Us</h4>
                        <div className="footer-pera">
                        <p>
                        we believe that every moment deserves to be celebrated in style. 
                        With years of experience in event planning and a passion for creating unforgettable experiences, we transform your visions into reality.
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5">
                <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                    <h4>Contact Info</h4>
                    <ul>
                        <li>
                        <p>
                            Address : 3H49+C48,3rd Cross St, Dodda Bommasandra, Bangalore - 560097 (Near Reliance Digital).
                        </p>
                        </li>
                        <li>
                        <a href="#">Phone : +91 73380 11713</a>
                        </li>
                        <li>
                        <a href="#">Email : vrvvinodmani@gmail.com</a>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5">
                <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                    <h4>Important Link</h4>
                    <ul>
                        <li>
                        <Link to="/" onClick={scrollToTop}>Home</Link>
                        </li>
                        <li>
                        <Link to="/about" onClick={scrollToTop}>About</Link>
                        </li>
                        <li>
                        <Link to="/gallery" onClick={scrollToTop}>Gallery</Link>
                        </li>
                        <li>
                        <Link to="/packages" onClick={scrollToTop}>Packages</Link>
                        </li>
                        <li>
                        <Link to="/contact" onClick={scrollToTop}>Contact</Link>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-5">
                <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                    <h4>Newsletter</h4>
                    <div className="footer-pera footer-pera2">
                        <p>
                        Heaven fruitful doesn't over lesser in days. Appear
                        creeping.
                        </p>
                    </div>
                  
                    </div>
                </div>
                </div>
            </div>
            <div className="row footer-wejed justify-content-between">
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5">
                <div className="footer-tittle-bottom">
                    <span>5000+</span>
                    <p>Clients</p>
                </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5">
                <div className="footer-tittle-bottom">
                    <span>20+</span>
                    <p>Talented team</p>
                </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5">
                <div className="footer-tittle-bottom">
                    <span>10+</span>
                    <p>years of Experiences</p>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div className="footer-bottom-area footer-bg">
            <div className="container">
            <div className="footer-border">
                <div className="row d-flex justify-content-between align-items-center">
                <div className="col-xl-10 col-lg-8 ">
                    <div className="footer-copy-right">
                    <p>
                        Copyright ©
                        <script
                        dangerouslySetInnerHTML={{
                            __html: "document.write(new Date().getFullYear());",
                        }}
                        />{" "}
                        All rights reserved | This template is made with{" "}
                        <i aria-hidden="true" className="fa fa-heart" /> by{" "}
                        <a href="https://colorlib.com" target="_blank">
                        Opt!ms
                        </a>
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
      </div>
    );
};

export default Footer;
