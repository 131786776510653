import traditionalPhotographer from "../images/trditionalphotographer.jpg";
import tattooArtist from "../images/tattoooo.jpg";
import balloonArt from "../images/baloonart.jpg";
import mugCaricature from "../images/mugca.jpg";
import facePainting from "../images/facepainting.jpg";
import nailArt from "../images/nailart.jpg";

export const funCategories = [
    {
      name: "Traditional photography",
    image: traditionalPhotographer,
      type: "Indoor",
      category: "Photography",
      price: 150,
      priceString: "$150 per hour",
      description:
        "Capture timeless memories with our traditional photography package.",
      includes: "Photographer, Editing, Digital Copies",
      rating: 4.5,
      reviews: [
        { reviewer: "Alice", comment: "Amazing experience!" },
        { reviewer: "John", comment: "Great quality photos!" },
      ],
    },

    {
      name: "Tattoo artist",
      image: tattooArtist,
      type: "Indoor",
      category: "Art",
      price: 100,
      priceString: "$100 per session",
      description: "Custom temporary tattoos for your guests.",
      includes: "Tattoo Artist, Tattoo Supplies",
      rating: 4.2,
      reviews: [{ reviewer: "Emma", comment: "Kids loved the tattoos!" }],
    },
    {
      name: "Balloon modelling",
    image: balloonArt,
      bannerImage:
        "https://via.placeholder.com/800x200?text=Balloon+Modelling+Banner",
      type: "Outdoor",
      category: "Entertainment",
      price: 80,
      priceString: "$80 per hour",
      description:
        "Fun balloon modelling to entertain children and adults alike.",
      includes: "Balloon Artist, Balloons",
      rating: 4.8,
      reviews: [{ reviewer: "Liam", comment: "Great fun for the kids!" }],
    },
    {
      name: "Mug Caricature",
      image: mugCaricature,
      bannerImage:
        "https://via.placeholder.com/800x200?text=Mug+Caricature+Banner",
      type: "Indoor",
      category: "Art",
      price: 15,
      priceString: "$15 per mug",
      description: "Customized caricature mugs as a unique souvenir.",
      includes: "Caricature Artist, Mug, Custom Drawing",
      rating: 4.4,
      reviews: [{ reviewer: "Olivia", comment: "Unique and fun souvenir!" }],
    },
    {
      name: "Face painting",
      image: facePainting,
      bannerImage:
        "https://via.placeholder.com/800x200?text=Face+Painting+Banner",
      type: "Indoor",
      category: "Art",
      price: 90,
      priceString: "$90 per hour",
      description: "Creative face painting for children and adults.",
      includes: "Face Painter, Paint Supplies",
      rating: 4.6,
      reviews: [{ reviewer: "Ava", comment: "Kids loved the face painting!" }],
    },
    {
      name: "Nail arts",
      image: nailArt,
      bannerImage: "https://via.placeholder.com/800x200?text=Nail+Arts+Banner",
      type: "Indoor",
      category: "Beauty",
      price: 50,
      priceString: "$50 per session",
      description: "Beautiful nail art designs for your guests.",
      includes: "Nail Artist, Nail Art Supplies",
      rating: 4.3,
      reviews: [{ reviewer: "Sophia", comment: "Beautiful designs!" }],
    },
    // Add more activities as needed
  ];