import React, { Fragment,useState } from 'react';

import Navigation from './navigation';
import Footer from './footer';
import { weddingpackages } from '../data/weddingdata';
import { Link } from "react-router-dom";
import "../css/Detailspage.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSearchParams, useNavigate } from 'react-router-dom';
import IndexBirthday from './indexbirthday';

function DetailPageWedding() {
  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <button className={`${className} custom-arrow custom-prev`} onClick={onClick}>
            <i className="fa fa-chevron-left"></i>
        </button>
    );
};
const scrollToTop = () => {
  window.scrollTo(0, 0); // Scroll to the top of the page
};

const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <button className={`${className} custom-arrow custom-next`} onClick={onClick}>
            <i className="fa fa-chevron-right"></i>
        </button>
    );
};
  
const [currentPage, setCurrentPage] = useState(1);
  const packagesPerPage = 3; // Number of packages per page

  // Calculate total pages
  const totalPages = Math.ceil(weddingpackages.length / packagesPerPage);

  // Get current packages for the page
  const indexOfLastPackage = currentPage * packagesPerPage;
  const indexOfFirstPackage = indexOfLastPackage - packagesPerPage;
  const currentPackages = weddingpackages.slice(indexOfFirstPackage, indexOfLastPackage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
   
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  
  };

const [searchParams] = useSearchParams();

// Extract the query parameters
const id = searchParams.get('id');
console.log(id);
const weddingpackage = weddingpackages.find(t => t.id === parseInt(id));
  
  const [formData, setFormData] = useState({
    message: '',
    name: '',
    email: '',
    selectedPackage: '', 
    date: '', // Add this line for the date field
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
 

  const handleSmsClick = () => {
    const phoneNumber = "+919019261365"; // Replace with the desired phone number
    const smsMessage = `Hello, I'm ${formData.name}. I'm interested in the ${formData.selectedPackage} on ${formData.date} package and would love to learn more about it. Could you please provide me with additional details and any special offers that might be available? I appreciate your assistance and look forward to hearing from you soon. Thank you!`;
    const smsLink = `sms:${phoneNumber}?body=${encodeURIComponent(smsMessage)}`;
    window.location.href = smsLink;
  };

  

  return (
    <Fragment>
    <div>
      <header style={{ height: "93px" }}>
        <Navigation />
      </header>
      <main>
        <a
          href="https://wa.me/+917619233640"
          className="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-whatsapp whatsapp-icon"></i>
        </a>

        <a
          href="tel:+917619233640"
          className="phone_float"
          rel="noopener noreferrer"
        >
          <i className="fa fa-phone phone-icon"></i>
        </a>
        <div className="slider-area2">
          <div className="slider-height2 d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="hero-cap hero-cap2 text-center">
                    <h2>Packages</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="blog_area section-padding">
          <div className=" container-1">
            <div className="row row-2">
              <div className="col-lg-6 fixed-sidebar">
                <div className="blog_right_sidebar">
                  <aside className="searchpad single_sidebar_widget search_widget">
                    <h2>{weddingpackage.name}</h2>
                  </aside>
                  <aside className="detail-img single_sidebar_widget post_category_widget">
                    <img style={{width:'100%'}}  src={`/${weddingpackage.image}`} alt={weddingpackage.name} />
                  </aside>
                  <article className="blog_item">
                  <div className="blog_details why-choose-us">
                      <Link className="d-inline-block"><h2>WHY CHOOSE US</h2></Link>
                      <ul>
                        <li>
                          <i className="fa fa-thumbs-up" ></i> 
                          <strong>Trusted Platform </strong> - More than 10,000 celebrations every month
                        </li>
                        <li>
                          <i className="fa fa-thumbs-up" ></i> 
                          <strong>Professional Team </strong> - Follows all Safety Measures & Sanitization Requirements
                        </li>
                        <li>
                          <i className="fa fa-thumbs-up" ></i> 
                          <strong>Complete Confidence </strong> - Browse all Verified Reviews and Original Photographs
                        </li>
                        <li>
                          <i className="fa fa-thumbs-up"></i> 
                          <strong>100% Refund </strong> - In case of non-availability, a complete refund of the total amount is initiated
                        </li>
                      </ul>
                    </div>
                  </article>
                </div>
              </div>
              <div className="detail-content col-lg-6">
                <div className="blog_left_sidebar">
                  <article  className="blog_item">
                        <div className="blog_item_img">
                        </div>
                        <div className="blog_details">
                        <ul className="blog-info-link">
                            <li>
                              <strong style={{display:'flex'}}>
                                <Link onClick={{scrollToTop}} to="/">
                                 Home <i className="fa fa-chevron-right"></i>
                                </Link>
                                <Link onClick={{scrollToTop}} to="/birthday">
                                 Birthday <i className="fa fa-chevron-right"></i>
                                </Link>
                                <p>
                                 {weddingpackage.name} 
                                </p>
                              </strong>
                            </li>
                          </ul>
                          <Link className="d-inline-block">
                            <h2 className="blog-head">{weddingpackage.name}</h2>
                          </Link>
                          <hr />
                          <p>{weddingpackage.text}</p>
                        </div>
                      </article>
                      <article className="blog_item">
                        <div className="blog_item_img">
                        </div>
                        <div className="blog_details">
                        <ul className="blog-info-link ">
                            <li className='price-detials'>
                              <strong>
                                <Link href="#">
                                 PRICE : <i className="fa fa-inr" />  {weddingpackage.price}/setup
                                </Link>
                              </strong>
                            </li>
                          </ul>
                          <hr />
                          <div className="d-inline-block" href="blog_details.html">
                          <div className="col-lg-12">
                              <form className="form-contact contact_form" id="contactForm" noValidate>
                                <div className="row">
                                <div className="col-12">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="selectedPackage"
                                      name="selectedPackage"
                                      value={formData.selectedPackage}
                                      onChange={handleChange}
                                    >
                                      <option value={weddingpackage.name}>{weddingpackage.name}</option>
                                    </select>
                                  </div>
                                </div>
                                  
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <input
                                        className="form-control valid"
                                        id="name"
                                        name="name"
                                        placeholder="Enter your name"
                                        type="text"
                                        value={formData.name}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <input
                                        className="form-control valid"
                                        id="email"
                                        name="email"
                                        placeholder="Email"
                                        type="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                     {/* Date Selector */}
                                      <div className="col-12">
                                        <div className="form-group">
                                          <input
                                            className="form-control valid"
                                            id="date"
                                            name="date"
                                            placeholder="Select a date"
                                            type="date"
                                            value={formData.date}
                                            onChange={handleChange}
                                          />
                                      </div>
                                  </div>
                                </div>
                                <div className="form-group mt-3">
                                  <button
                                    className="button button-contactForm boxed-btn"
                                    type="button"
                                    onClick={handleSmsClick}
                                  >
                                    Send via SMS
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                          <p>{weddingpackage.aboutExperince}</p>
                        </div>
                      </article>
                      <article  className="blog_item">
                        <div className="blog_item_img">
                        </div>
                        <div className="blog_details">
                          <Link className="d-inline-block" >
                            <h2 className="blog-head">INCLUSIONS</h2>
                          </Link>
                          <hr />
                          <ul>{weddingpackage.inclusions
                              .map((inclusions) => (
                               <li> <i className="fa fa-check-circle" style={{ color: 'green' }}></i> {inclusions.include}</li>
                              ))}</ul>
                        </div>
                      </article>
                      <article  className="blog_item">
                        <div className="blog_item_img">
                        </div>
                        <div className="blog_details">
                          <Link className="d-inline-block" href="blog_details.html">
                            <h2 className="blog-head">Location</h2>
                          </Link>
                          <hr />
                          <h5>At Your Location</h5>
                        </div>
                      </article>
                      <article  className="blog_item">
                        <div className="blog_item_img">
                        </div>
                        <div className="blog_details">
                          <Link className="d-inline-block" href="blog_details.html">
                            <h2 className="blog-head">Canellation Policy</h2>
                          </Link>
                          <hr />
                          <ul>
                          <li>More than 24 hours before experience: Cancellation with 90% Refund </li>
                          <li>12 hours to 24 hours before an experience: Cancellation with 50% Refund</li>
                          <li>Less than 12 hours before experience: No Refund on Cancellation</li>
                          </ul>
                        </div>
                      </article>
                </div>
              </div>
            </div>
            <div className="related-cards">

          <div className="testimonial-section">
          <h2>Similar Packages</h2>
          <section className="home-blog-area section-padding30">
            <div className="container container-1">
              <div className="row">
                {currentPackages.map((weddingpackage, index) => (
                  <div className="col-xl-4 col-lg-6 col-md-6" key={index}>
                    <div className="home-blog-single mb-30">
                      <div className="blog-img-cap">
                        <div className="blog-img">
                          <img src={`/${weddingpackage.image}`} alt="" />
                          <div className="blog-date text-center">
                            <p>Rs.{weddingpackage.price}/-</p>
                          </div>
                        </div>
                        <div className="blog-cap blog-cap-1">
                          <h3>{weddingpackage.name}</h3>
                          <p>{weddingpackage.text}</p>
                          <Link
                            to={{
                              pathname: '/detailsWedding',
                              search: `?id=${weddingpackage.id}&name=${encodeURIComponent(weddingpackage.name)}`,
                              state: { package: weddingpackage }, // Pass additional data via state if needed
                            }}
                            onClick={scrollToTop}
                            className="btn hero-btn hero_btn_main"
                            data-animation="fadeInLeft"
                            data-delay="1.0s"
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="pagination">
                <button
                  className="pagination-button"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className="pagination-button"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </section>
      <style jsx>{`
        .pagination {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
        }

        .pagination-button {
          background-color: #e6005c;
          color: white;
          border: none;
          border-radius: 4px;
          padding: 10px 20px;
          margin: 0 5px;
          cursor: pointer;
          font-size: 16px;
          transition: background-color 0.3s ease;
        }

        .pagination-button:hover {
          background-color: white;
          color: #e6005c;
        }

        .pagination-button:disabled {
          background-color: #c0c0c0;
          cursor: not-allowed;
        }

        .pagination-info {
          margin: 0 15px;
          font-size: 16px;
        }
      `}</style>
         </div>
         <div className="category-section">
         <article  className="blog_item">
                        <div className="blog_item_img">
                        </div>
                        <div className="blog_details">
                        <ul className="blog-info-link">
                            <li>
                              <strong style={{display:'flex'}}>
                                <Link onClick={{scrollToTop}} to="/">
                                 Home <i className="fa fa-chevron-right"></i>
                                </Link>
                                <Link onClick={{scrollToTop}} to="/birthday" >
                                 Birthday <i className="fa fa-chevron-right"></i>
                                </Link>
                                <p>
                                  {weddingpackage.name}
                                </p>
                              </strong>
                            </li>
                          </ul>
                          <Link className="d-inline-block">
                            <h2 className="blog-head">Similar Categories</h2>
                          </Link>
                          <hr />
                          <p>
                                 <Link onClick={{scrollToTop}} to="/birthday">#birthdaydecors</Link>
                                 <Link onClick={{scrollToTop}} to="/birthday">#babyshower</Link>
                                 <Link onClick={{scrollToTop}} to="/birthday">#weddingdecor</Link>
                                 <Link onClick={{scrollToTop}} to="/birthday">#flowerdecor</Link>
                                 <Link onClick={{scrollToTop}} to="/birthday">#fun activities</Link>
                                 <Link onClick={{scrollToTop}} to="/birthday">#More</Link>
                           </p>
                        </div>
                      </article>

         </div>
         
          </div>
           
          </div>
        </section>

      </main>

      <footer>
        <Footer />
      </footer>
      <div id="back-top">
        <a href="#" title="Go to Top">
          <i className="fas fa-level-up-alt" />
        </a>
      </div>
    </div>
  </Fragment>

  );
}

export default DetailPageWedding;

