import React, { Fragment, useState } from "react";
import Navigation from "./navigation";
import Footer from "./footer";

function Gallery() {
  const images = [
    "assets/event/BabyShower/bs3.jpg",
    "assets/event/BabyShower/bs4.jpg",
    "assets/event/BabyShower/bs5.jpg",
    "assets/event/BabyShower/bs8.jpg",
    "assets/event/BabyShower/bs10.jpg",
    "assets/event/BabyShower/bs12.jpg",
    "assets/event/BabyShower/bs17.jpg",
    "assets/event/Birthday/bb1.jpg",
    "assets/event/Birthday/bb3.jpg",
    "assets/event/Birthday/bb4.jpg",
    "assets/event/Birthday/bb5.jpg",
    "assets/event/Birthday/bb6.jpg",
    "assets/event/Birthday/bb7.jpg",
    "assets/event/Birthday/bb8.jpg",
    "assets/event/Birthday/bb9.jpg",
    "assets/event/Birthday/bb10.jpg",
    "assets/event/Birthday/bb11.jpg",
    "assets/event/Birthday/bb12.jpg",
    "assets/event/Birthday/bb13.jpg",
    "assets/event/Birthday/bb14.jpg",
    "assets/event/Birthday/bb15.jpg",
    "assets/event/Birthday/bb16.jpg",
    "assets/event/Birthday/bb17.jpg",
    "assets/event/Birthday/bb18.jpg",
    "assets/event/Birthday/bb19.jpg",
    "assets/event/Birthday/bb20.jpg",
    "assets/event/Birthday/bb21.jpg",
    "assets/event/Birthday/bb22.jpg",
    "assets/event/Birthday/bb23.jpg",
    "assets/event/Birthday/bb24.jpg",
    "assets/event/HalfSaree/hs1.jpg",
    "assets/event/HalfSaree/hs2.jpg",
    "assets/event/NamingCeremoney/nc1.jpg",
    "assets/event/NamingCeremoney/nc2.jpg",
    "assets/event/Wedding/wb1.jpg",
    "assets/event/Wedding/wb2.jpg",
    "assets/event/Wedding/wb3.jpg",
    "assets/event/Wedding/wb4.jpg",
    "assets/event/Wedding/wb5.jpg",
    "assets/event/Wedding/wb6.jpg",
    "assets/event/Wedding/wb7.jpg",
    "assets/event/Wedding/wb8.jpg",
    "assets/event/Wedding/wb9.jpg",
    "assets/event/Wedding/wb10.jpg",
    "assets/event/Wedding/wb11.jpg",
    "assets/event/Birthday/eb1.jpg",
    "assets/event/Birthday/eb2.jpg",
    "assets/event/Birthday/eb3.jpg",
    "assets/event/Birthday/eb4.jpg",
    "assets/event/Birthday/eb5.jpg",
    "assets/event/Birthday/eb6.jpg",
    "assets/event/Birthday/eb7.jpg",
    "assets/event/Birthday/eb8.jpg",
    "assets/event/Birthday/eb9.jpg",
    "assets/event/Birthday/eb10.jpg",
    "assets/event/Birthday/eb12.jpg",
    "assets/event/Birthday/eb11.jpg",
    "assets/event/Birthday/eb14.jpg",
    "assets/event/Birthday/eb13.jpg",
    "assets/event/Birthday/eb15.jpg",
    "assets/event/Birthday/eb16.jpg",
    "assets/event/Birthday/eb17.jpg",
    "assets/event/Birthday/eb18.jpg",
    "assets/event/Birthday/eb19.jpg",
    "assets/event/Birthday/eb20.jpg",
    "assets/event/Birthday/eb21.jpg",
    "assets/event/Birthday/eb22.jpg",
    "assets/event/Birthday/eb23.jpg",
    "assets/event/Birthday/eb24.jpg",
    "assets/event/Birthday/eb25.jpg",
    "assets/event/Birthday/eb26.jpg",
    "assets/event/Birthday/eb27.jpg",
    "assets/event/Birthday/eb28.jpg",
    "assets/event/Birthday/eb29.jpg",

  ];

  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 16;

  // Calculate total pages
  const totalPages = Math.ceil(images.length / imagesPerPage);

  // Get current images for the page
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <Fragment>
      <div>
        <header style={{ height: "93px" }}>
          <Navigation />
        </header>
        <main>
          <a
            href="https://wa.me/+917619233640"
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-whatsapp whatsapp-icon"></i>
          </a>

          <a
            href="tel:${+917619233640}"
            className="phone_float"
            rel="noopener noreferrer"
          >
            <i className="fa fa-phone phone-icon"></i>
          </a>
          <div className="slider-area2">
            <div className="slider-height2 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="hero-cap hero-cap2 text-center">
                      <h2>Gallery</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="whole-wrap">
            <div className="container box_1170">
              <div className="section-top-border">
    
                <div className="row gallery-item">
                  {currentImages.map((image, index) => (
                    <div key={index} className="col-md-3">
                      <a className="img-pop-up" href={image}>
                        <div
                          className="single-gallery-image"
                          style={{
                            background: `url(${image})`,
                          }}
                        />
                      </a>
                    </div>
                  ))}
                </div>
                <div className="pagination">
                  <button
                    className="pagination-button"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    className="pagination-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
        <div id="back-top">
          <a href="#" title="Go to Top">
            {" "}
            <i className="fas fa-level-up-alt" />
          </a>
        </div>
      </div>
      <style jsx>{`
        .pagination {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
        }

        .pagination-button {
          background-color: #e6005c;
          color: white;
          border: none;
          border-radius: 4px;
          padding: 10px 20px;
          margin: 0 5px;
          cursor: pointer;
          font-size: 16px;
          transition: background-color 0.3s ease;
        }

        .pagination-button:hover {
          background-color: white;
          color: #e6005c;
        }

        .pagination-button:disabled {
          background-color: #c0c0c0;
          cursor: not-allowed;
        }

        .pagination-info {
          margin: 0 15px;
          font-size: 16px;
        }
      `}</style>
    </Fragment>
  );
}

export default Gallery;
